import { motion } from 'motion/react';
import useTranslations from '../../../i18n/useTranslations';

import { Icon, Icons } from '../../Icon';
import graphicWhat from '../assets/graphic-what.svg';
import styles from './RationaleWhat.module.css';

const RationaleWhat = () => {
  const { t } = useTranslations();
  return (
    <section className={styles.root}>
      <motion.div
        initial={{ opacity: 0, y: 32 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.3 } }}
        viewport={{ once: true }}
      >
        <div className={styles.boxIcons}>
          <div className={styles.icon}>
            <Icon size={20} name={Icons.Home} />
            <span>{t('landing:rationaleWhat:icon:1')}</span>
          </div>
          <span className={styles.separator}>|</span>
          <div className={styles.icon}>
            <Icon size={20} name={Icons.Briefcase} />
            <span>{t('landing:rationaleWhat:icon:2')}</span>
          </div>
        </div>
        <h2 className={styles.title}>{t('landing:rationaleWhat:title')}</h2>
        <h3 className={styles.description}>{t('landing:rationaleWhat:description')}</h3>
      </motion.div>
      <motion.div
        className={styles.graphicImage}
        initial={{ opacity: 0, y: 32 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.4 } }}
        viewport={{ once: true }}
      >
        <img src={graphicWhat} alt="graphic" className={styles.image} />
      </motion.div>
    </section>
  );
};

export default RationaleWhat;
