import { forwardRef } from 'react';

import useTranslations from '../../../../i18n/useTranslations';
import { Icons, Icon } from '../../../Icon';
import styles from './Dialog.module.css';

const Dialog = forwardRef(({ onClose, children }, ref) => {
  const { t } = useTranslations();
  return (
    <dialog ref={ref} className={styles.root}>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <button className={styles.close} type="button" onClick={onClose}>
            <span className="visually-hidden">{t('action:close')}</span>
            <Icon size={32} name={Icons.Close} />
          </button>
        </header>
        <div className={styles.content}>{children}</div>
      </div>
    </dialog>
  );
});

export default Dialog;
