import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';

import useTranslations from '../../i18n/useTranslations';
import { ROUTES } from '../../routes';
import services from '../../services';
import { useAuth, useForm } from '../../context';
import { LOGIN_FORM_INPUTS } from '../../constants';
import { loginSchema } from '../../validationSchemas';

import { PublicLayout } from '../../Layout';
import { FormikInput } from '../Input';
import { Button } from '../Button';
import { Link } from '../Link';
import { Icons, Icon } from '../Icon';
import styles from './Login.module.css';
import stylesForm from '../Form/Form.module.css';
// import { SocialLogin } from '../SocialLogin';

const { registerLogin } = services;

const Login = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { refreshContext } = useForm();
  const { checkToken } = useAuth();
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleNextStep = async (values) => {
    try {
      const res = await registerLogin(values);
      localStorage.setItem('token', res.access_token);
      await checkToken();
      const { userIsAdmin, userDetailsRegistered, anyEstimatedBill, isTempPassword } =
        await refreshContext();

      if (isTempPassword) {
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_PASSWORD}`);
        return;
      }

      if (userIsAdmin) {
        navigate(ROUTES.ADMIN);
        return;
      }

      if (!userDetailsRegistered) {
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_NEW_TYPE}`);
        return;
      }

      if (!anyEstimatedBill) {
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_ADDRESS}`);
        return;
      }
      navigate(ROUTES.DASHBOARD);
      return;
    } catch (error) {
      setError(t('form:error:invalid_credentials'));
    }
  };

  return (
    <PublicLayout>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('login:heading')}</h1>
        </header>
        <div className={styles.content}>
          <Formik
            initialValues={LOGIN_FORM_INPUTS}
            validationSchema={loginSchema}
            onSubmit={handleNextStep}
          >
            {({ errors, touched }) => (
              <Form className={stylesForm.form} noValidate>
                <div className={stylesForm.formGroup}>
                  <div>
                    <FormikInput
                      type="email"
                      label="input:email"
                      name="email"
                      error={errors['email']}
                      touched={touched['email']}
                      inputMode="email"
                      required
                    />
                  </div>
                  <div className={stylesForm.passwordContainer}>
                    <FormikInput
                      type={showPassword ? 'text' : 'password'}
                      label="input:password"
                      name="password"
                      error={errors['password']}
                      touched={touched['password']}
                      inputMode="text"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className={stylesForm.togglePassword}
                    >
                      <Icon size={20} name={showPassword ? Icons.EyeSlash : Icons.Eye} />
                      <span className="visually-hidden">
                        {showPassword ? t('action:hide_password') : t('action:show_password')}
                      </span>
                    </button>
                  </div>

                  {error && <p className={stylesForm.errorMsg}>{error}</p>}

                  {/* <SocialLogin /> */}

                  <div>
                    <p className={stylesForm.formText}>
                      {t('action:forgotten_password')}
                      {' '}
                      <Link to={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_NEW_PASSWORD_REQUEST}`}>
                        {t('action:forgotten_password_get')}
                      </Link>
                    </p>
                  </div>
                </div>

                <div className={stylesForm.formActions}>
                  <Button isFullWidth type="submit">
                    {t('action:continue')}
                  </Button>
                </div>
                <div className={styles.marginLink}>
                  <p className={stylesForm.formText}>
                    {t('action:new_account')}
                    {' '}
                    <Link to={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SIGN_UP}`}>
                      {t('action:new_account_here')}
                    </Link>
                  </p>
                </div>

              </Form>
            )}
          </Formik>
        </div>
      </section>
    </PublicLayout>
  );
};

export default Login;
