export const ROUTE_PARAMS = {
  TYPE: ':type',
  CONTRACT_ID: ':id',
  BILL_ID: ':billId',
  BILL_VIEW: '?view=bill',
  CONTRACT_VIEW: '?view=contract',
};

export const ROUTES = {
  ROOT: '/sign-in',
  DASHBOARD: '/dashboard',
  LEGAL_NOTICE: '/legal-notice',
  TandCs: '/TtT&CcCs',
  PRIVACY_POLICY: '/privacy-policy',
  COOKIES_POLICY: '/cookies-policy',
  ACCOUNT: '/account/*',
  ACCOUNT_ROOT: '/account',

  LANDING: '/',

  ACCOUNT_WAITLIST: '/waitlist',
  ACCOUNT_EMAIL: '/email',
  STRIPE_CHECKOUT: '/checkout',
  ACCOUNT_PASSWORD: '/new-password',
  ACCOUNT_ADDRESS: '/address',
  ACCOUNT_NEW_TYPE: '/new-type',
  ACCOUNT_SUCCESS: '/success',
  ACCOUNT_AUTH_SIGN: '/auth-sign',
  ACCOUNT_IBAN: '/iban',
  ACCOUNT_NEW_USER: '/new-user',
  ACCOUNT_ESTIMATED_RESULTS: '/estimated-results',
  ACCOUNT_NEW_PASSWORD_REQUEST: '/request-new-password',
  ACCOUNT_SIGN_UP: '/sign-up',
  ACCOUNT_RECOVER_PASS_SUCCESS: '/recover-pass-success',

  CONTRACT: `/contract/${ROUTE_PARAMS.TYPE}/${ROUTE_PARAMS.CONTRACT_ID}`,
  BILL: `/contract/${ROUTE_PARAMS.TYPE}/${ROUTE_PARAMS.CONTRACT_ID}/bill/${ROUTE_PARAMS.BILL_ID}`,

  ADMIN: '/admin',
  ADMIN_OPS: '/admin/*',
  LATE_LIGHT_BILLS: '/late-light-bills',
  CONTRACTS_TO_ONBOARD: '/contracts-to-onboard',
  ADD_NEW_BILLS: '/add-new-bills',
  BILLS_TO_SEND: '/bills-to-send',
  NON_PROCESSED_BILLS: '/non-processed-bills',
  ONBOARDING_BILLS_TO_VERIFY: '/onboarding-bills-to-verify',
  IMPERSONATE_USER: '/impersonate-user',
  GENERAL_STATUS: '/general-status',
  ONBOARDED_CONTRACTS_TO_VERIFY: '/onboarded-contracts-to-verify',

  FAQS: '/faqs',
};
