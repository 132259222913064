import React from 'react';
import { ErrorMessage } from 'formik';
import useTranslations from '../../i18n/useTranslations';
import stylesForm from '../Form/Form.module.css';
import styles from './RadioButton.module.css';

const RadioButtonGroup = ({ error, touched, label, children, disabled = false }) => {
  const { t } = useTranslations();

  return (
    <div className={styles.radioButtonGroup}>
      <legend className={styles.radioButtonGroupLegend}>{t(label)}</legend>
      <div className={styles.radioButtonGroupOptions}>
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { disabled });
          }
          return child;
        })}
      </div>
      {touched && error && (
        <ErrorMessage
          render={() => (
            <span className={`${stylesForm.errorMsg} ${stylesForm.noPadding}`}>{t(error)}</span>
          )}
        />
      )}
    </div>
  );
};

export default RadioButtonGroup;
