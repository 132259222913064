import styles from './LegalPolicies.module.css';


const tandCs = () => {
  return (
    <article className={styles.root}>
      <header>
        <h1>CONDICIONES LEGALES</h1>
      </header>
      <div className={styles.content}>
        <p>Por favor, lea atentamente las presentes condiciones legales antes de aceptar las mismas.</p>
        
        <h2>INTRODUCCIÓN E INFORMACIÓN GENERAL</h2>
        
        <p>Estas condiciones generales (las "Condiciones Legales" o las "Condiciones") establecen las advertencias, términos y condiciones aplicables al uso de los medios técnicos y servicios ofrecidos por parte de JIRO SIMPLIFIED BILLS S.L. y/o cualquiera de sus entidades filiales, su matriz, o cualquiera de las entidades del grupo de sociedades de JIRO SIMPLIFIED BILLS S.L. (conjuntamente, "Zenvi" o "Nosotros") a través de https://zenvi.es/, así como de cualesquiera aplicaciones para dispositivos móviles o cualesquiera otros dispositivos que, en su caso, se habiliten para su utilización por Nosotros (conjuntamente, la "Plataforma").</p>
        
        <p>Las presentes Condiciones constituyen un acuerdo vinculante entre usted (el "Usuario" o "Usted") y Nosotros en nuestra condición de titulares y propietarios de la Plataforma y/o prestadora de servicio a través de la Plataforma.</p>
        
        <p>Usted y Nosotros seremos denominados, en adelante y conjuntamente, como las "Partes" o, individualmente y cuando proceda, como la "Parte".</p>
        
        <p>Mediante la aceptación de las presentes Condiciones, usted manifiesta y garantiza a Zenvi que está suscribiendo y aceptando las Condiciones en su propio nombre y representación o en nombre y representación del Usuario (en el caso de que el Usuario sea una persona jurídica), y que tiene capacidad legal suficiente para aceptar y vincular al Usuario por las mismas.</p>
        
        <p>En el supuesto en el que Usted no quiera estar vinculado por las presentes Condiciones, usted no deberá aceptar las presentes Condiciones y deberá dejar de utilizar todos y cada uno de los servicios y/o productos ofrecidos a través de la Plataforma.</p>
        
        <h2>ACEPTACIÓN DE LAS CONDICIONES LEGALES</h2>
        
        <p>La mera utilización de la Plataforma (aunque se haga como comerciante o empresario bajo convenio con Zenvi) conlleva automáticamente la aceptación plena y sin reserva por el Usuario de todas y cada una de las disposiciones previstas en las presentes Condiciones Legales en la versión publicada por Zenvi en cada momento. En consecuencia, el Usuario debe leer periódicamente las Condiciones Legales para conocer sus variaciones, que no necesariamente deberán ser advertidas por parte de Zenvi al Usuario.</p>
        
        <p>Además de las Condiciones Legales, resultarán de aplicación al Usuario aquellas otras condiciones generales y/o particulares que suscriban en relación con la utilización de determinados recursos y/o contenidos y/o productos y/o servicios puestos a su disposición en la Plataforma por parte de Zenvi. En caso de contradicción entre las condiciones particulares y las generales, prevalecerán las particulares sobre los términos estipulados en las generales.</p>
        
        <h2>CONDICIONES DE ACCESO Y USO DE LA PLATAFORMA</h2>
        
        <p>Si Usted no cumple con todos y cada uno de los requisitos determinados en estas Condiciones, no podrá registrarse en la Plataforma y beneficiarse de los Servicios ofrecidos a través de la misma.</p>
        
        <p>Entre otros requisitos previstos en las presente Condiciones, la Plataforma no se dirige en ningún caso a menores de 18 años, por consiguiente, SI ERES MENOR DE 18 AÑOS NO UTILICES LA PLATAFORMA NI NINGUNO DE LOS PRODUCTOS Y/O SERVICIOS PREVISTOS EN LA MISMA, NI TE REGISTRES, NI NOS FACILITES NINGÚN DATO PROPIO O DE TERCEROS.</p>
        
        <p>El acceso a la Plataforma y a los servicios y/o productos ofrecidos a través de la misma (los "Servicios") tampoco están disponibles para aquellos Usuarios a los que Zenvi haya excluido, restringido o prohibido los mismos con anterioridad, ni para aquellas personas que tengan prohibido usarlos de conformidad con las leyes de España o las leyes aplicables de cualquier otra jurisdicción.</p>
        
        <p>Para la navegación en la Plataforma no será necesario el registro, sin perjuicio de que, para poder beneficiarse de los Servicios ofrecidos a través de la Plataforma, el Usuario deberá completar el proceso de registro a la misma.</p>
        
        <p>Una vez completado el proceso de registro en la Plataforma, Zenvi le facilitará sus claves personales de acceso, privadas e intransferibles, que habrá de introducir a fin de poder acceder a su cuenta en la Plataforma (la "Cuenta"). El Usuario se compromete a su uso diligente y a mantener la confidencialidad de la misma. En consecuencia, los Usuarios son responsables de la adecuada custodia y confidencialidad de cualesquiera identificadores y/o contraseñas que le sean suministrados por Zenvi, y se comprometen a no ceder su uso a terceros, ya sea temporal o permanente, ni a permitir su utilización por personas ajenas. Será responsabilidad exclusiva del Usuario la utilización y/o contratación de los productos o servicios ofrecidos en la Página, por parte de cualquier tercero que, de forma ilegítima, emplee a tal efecto una contraseña o identificador suministrado al Usuario, a causa de una actuación no diligente o de la pérdida de la misma por el Usuario.</p>
        
        <p>En virtud de lo anterior, es obligación del Usuario notificar de forma inmediata a Zenvi, cualquier hecho que permita el uso indebido de los identificadores y/o contraseñas, tales como el robo, extravío, o el acceso no autorizado a los mismos por parte de terceros, con el fin de proceder a su inmediata cancelación. Mientras no se comuniquen tales hechos, Zenvi quedará eximida de cualquier responsabilidad que pudiera derivarse del uso indebido de los identificadores o contraseñas por parte de terceros no autorizados.</p>
        
        <p>El Usuario se compromete a hacer un uso adecuado de los Contenidos y servicios que Zenvi ofrece a través de su Página y con carácter enunciativo, pero no limitativo, a no emplearlos para: (i) incurrir en actividades ilícitas, ilegales, fraudulentas o contrarias a la buena fe y al orden público; (ii) difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos; (iii) provocar daños en los sistemas físicos y lógicos de Zenvi, de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados; (iv) intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar o manipular sus mensajes; (v) llevar a cabo cualquier conducta que pudiera dañar la imagen, los intereses y/o los derechos de Zenvi o de cualquier tercero; y (vi) realizar acto alguno con objeto de dañar, inutilizar o sobrecargar la Página o impedir, de cualquier forma, la normal utilización y funcionamiento de la misma.</p>
        
        <p>Zenvi se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran adecuados para su publicación. En cualquier caso, Zenvi no será responsable de las opiniones vertidas por el Usuario a través de los foros, chats, u otras herramientas de participación o de los contenidos que, en contravención de las cláusulas, establecidas en las presentes Condiciones de Uso o específicas habilitadas en la Página, el Usuario pudiera enviar o publicar, siendo el mismo el único responsable de la veracidad y licitud de los mismos.</p>
        
        <p>Se prohíbe el uso de direcciones postales, números de teléfono y direcciones de correo electrónico publicadas en esta Página para fines de marketing. Zenvi se reserva el derecho de emprender las acciones legales pertinentes contra quienes envíen mensajes de spam no deseados.</p>
        
        <h2>ASPECTOS GENERALES DE LOS SERVICIOS</h2>
        
        <p>La Plataforma tiene por finalidad facilitar y optimizar la selección y gestión de Proveedores de suministros de luz, gas, telefonía, etc. (en adelante el "Proveedor") contratados por el Usuario en su condición de empresa o persona física. Asimismo, los Servicios pueden permitir el intercambio de informaciones y mensajes relativos a las facturas enviadas por parte del Proveedor y poner a disposición determinadas herramientas para almacenar y gestionar las facturas.</p>
        
        <p>De conformidad con lo anterior, en los Servicios accesibles a través de la Plataforma, Zenvi actúa como un mero prestador de servicios de intermediación, a los efectos de que el Proveedor y el Usuario puedan llevar a cabo una serie de actuaciones vinculadas a la gestión de sus contratos y facturas. En función del servicio otorgado Zenvi solicitará al Usuario una autorización escrita que acredite a Zenvi a actuar en su representación frente a los Proveedores.</p>
        
        <h2>SERVICIOS DISPONIBLES EN LA PLATAFORMA</h2>
        
        <p>En la Plataforma, el Usuario que se hubiese registrado podrá llevar a cabo todas o parte de las siguientes acciones principales a través de su Cuenta:</p>
        
        <ul>
          <li>Realizar análisis de sus facturas o contratos actuales y contrastarlas con las alternativas propuestas por Zenvi.</li>
          <li>Seleccionar la oferta propuesta por Zenvi, delegando en Zenvi la negociación y gestión con los Proveedores para el cambio e implementación del nuevo contrato.</li>
          <li>Conceder permisos o dar acceso a la Cuenta o perfil del Usuario a empleados o colaboradores del Usuario (las "Personas Autorizadas") si fuera necesario, a los efectos de que puedan acceder a la misma.</li>
          <li>Comunicarse con Zenvi en cualquier momento.</li>
          <li>Visualizar, consultar, descargar y acceder a las facturas relativas a cada contrato alojadas en la Plataforma, tanto cuando hayan sido alojadas por el Proveedor como cuando hayan sido alojadas por Zenvi.</li>
          <li>Conocer el estado de sus facturas en cada instante, monitorizar diferentes métricas relevantes de ahorro, contrastar contra precios de mercado, etc.</li>
        </ul>
        
        <p>Además de los Servicios indicados en los apartados anteriores, Zenvi podrá poner a disposición del Usuario nuevas funcionalidades o servicios que se regirán por las presentes Condiciones Legales y, en su caso, por las condiciones particulares que se establezcan en relación con cada uno de ellos. Dichas funcionalidades dependerán de lo que permita la Plataforma y Zenvi en cada momento, sin que Zenvi tenga ningún tipo de obligación legal a facilitar las mimas.</p>
        
        <h3>Derecho de Desistimiento</h3>
        
        <p>El Usuario tiene derecho a desistir del presente contrato en un plazo de 14 días naturales sin necesidad de justificación. El plazo de desistimiento expirará a los 14 días naturales del día de la celebración del contrato. Para ejercer el derecho de desistimiento, el Usuario deberá notificar a Zenvi, a través de [dirección postal, número de fax o correo electrónico], su decisión de desistir del contrato a través de una declaración inequívoca (por ejemplo, una carta enviada por correo postal, fax o correo electrónico). El Usuario podrá utilizar el modelo de formulario de desistimiento que figura a continuación, aunque su uso no es obligatorio.</p>
        
        <p>Para cumplir con el plazo de desistimiento, basta con que la comunicación relativa al ejercicio por parte del Usuario de este derecho sea enviada antes de que venza el plazo correspondiente.</p>
        
        <p>En caso de desistimiento por parte del Usuario, Zenvi reembolsará todos los pagos recibidos del Usuario, incluidos, en su caso, los gastos de entrega (con la excepción de los gastos adicionales resultantes de la elección por parte del Usuario de una modalidad de entrega diferente a la modalidad menos costosa de entrega ordinaria que ofrezcamos) sin ninguna demora indebida y, en todo caso, a más tardar 14 días naturales a partir de la fecha en la que se nos informe de la decisión de desistir del presente contrato. Procederemos a efectuar dicho reembolso utilizando el mismo medio de pago empleado por el Usuario para la transacción inicial, a no ser que el Usuario haya dispuesto expresamente lo contrario; en todo caso, no incurrirá en ningún gasto como consecuencia del reembolso.</p>
        
        <h2>PRECIO DE LOS SERVICIOS PRESTADOS POR ZENVI</h2>
        
        <p>El Usuario deberá abonar una contraprestación a Zenvi por la utilización de los Servicios previamente descritos, en función del plan elegido por parte del Usuario. El importe concreto a abonar por parte del Usuario y las condiciones específicas se detallarán por parte de Zenvi en el momento de contratación del plan o Servicio contratado o en otros comunicados que podrá consultar el Usuario.</p>
        
        <p>Asimismo, eventualmente, Zenvi podrá, previa notificación al Usuario, establecer o modificar el coste para la utilización del Servicio, por ejemplo, a partir de un determinado número de servicios asociadas al Usuario, o número de Proveedores gestionados por Zenvi a estos efectos.</p>
        
        <p>Adicionalmente, Zenvi podrá establecer el carácter oneroso de un determinado servicio o funcionalidad cuando se establezca en las correspondientes condiciones particulares que el Usuario deberá aceptar.</p>
        
        <p>Zenvi puede variar los términos de las presentes Condiciones Legales. En este sentido, podrá sujetar a remuneración la utilización de Zenvi en todo caso y podrá variar o revisar la remuneración por la prestación de sus Servicios que resulte aplicable en cada momento, así como los términos de esta, informando de ello a los Usuarios con 30 días de antelación. La nueva contraprestación a abonar por parte del Usuario y sus términos serán aplicables a partir del inicio del siguiente periodo de facturación inmediatamente posterior a la notificación del cambio de condiciones o contraprestaciones al Usuario.</p>
        
        <h2>MÉTODOS DE PAGO</h2>
        
        <p>Para utilizar los servicios ofrecidos por Zenvi a través de la Plataforma, el Usuario deberá proporcionar uno o más métodos de pago. En el supuesto en el que el método de pago principal sea rechazado o surja cualquier impedimento que genere la imposibilidad por parte de Zenvi de percibir la cuota o contraprestación de los Servicios, el Usuario autoriza a Zenvi a cobrar la cuota o contraprestación a través de otro método de pago asociado a la Cuenta del Usuario.</p>
        
        <p>El Usuario podrá modificar en cualquier momento el método de pago. Asimismo, Zenvi también podrá modificar los métodos de pago.</p>
        
        <p>Zenvi manifiesta al Usuario que algunos métodos de pago pueden cobrar o cargar ciertas tarifas por conceptos como una operación en moneda extranjera u otros cargos relacionados con el procesamiento del método de pago. Los cargos por impuestos locales pueden variar dependiendo del método de pago usado. A tal efecto, el Usuario deberá consultar con el proveedor de servicio del método de pago para obtener detalles sobre todo lo anterior.</p>
        
        <h2>IMPAGO POR PARTE DE LOS USUARIOS</h2>
        
        <p>En el supuesto en el que Zenvi no logre cobrar un importe (por motivos como la caducidad del método de pago, falta de fondos o cualquier otra causa) y el Usuario no cancele la Cuenta, Zenvi se reserva el derecho de suspender o bloquear el acceso al Servicio, a la Cuenta y/o a la Plataforma hasta que se haya producido el abono de la deuda existente a través de un método de pago válido. En particular, una vez finalizado el plazo de quince (15) días desde que se hubiese generado la deuda a favor de Zenvi sin que el Usuario hubiese abonado la correspondiente contraprestación, el Usuario no podrá utilizar ni acceder a los Servicios, la Cuenta y la Plataforma de Zenvi.</p>
        
        <p>El bloqueo por impago no exime al Usuario del pago de la correspondiente contraprestación o deuda devengada.</p>
        
        <h2>CANCELACIÓN</h2>
        
        <p>El Usuario podrá cancelar la suscripción a Zenvi en cualquier momento y seguirá teniendo acceso al Servicio hasta el final del periodo de facturación o permanencia que corresponda. En función del plan o servicios contratados puede existir una penalización por cancelación anticipada.</p>
        
        <p>Las cantidades abonados por parte del Usuario no son en ningún momento reembolsables y Zenvi no facilitará en ningún momento devoluciones ni abonos por periodos parciales de mensualidades ni por Servicios no utilizados por parte del Usuario, salvo en lo establecido en el derecho de desistimiento.</p>
        
        <p>Para cancelar la suscripción se deberá acceder a la Plataforma y seguir las instrucciones que se establecen en la misma para la cancelación de la suscripción. En el supuesto de cancelación de la suscripción, la Cuenta se cerrará automáticamente al final del correspondiente periodo de facturación corriente.</p>
        
        <h2>USO RESPONSABLE DE ZENVI</h2>
        
        <p>El Usuario utilizará Zenvi en su propio nombre y por cuenta propia, bajo su entera y sola responsabilidad y para la gestión administrativa de las facturas de su actividad personal o empresarial conectadas con los servicios prestados o productos facilitados a Clientes, de conformidad con lo dispuesto en las presentes Condiciones.</p>
        
        <p>Al margen del uso indicado en el apartado anterior, el Usuario no utilizará la Plataforma con ninguna otra finalidad y, en particular, no podrá utilizar la Plataforma con finalidad lucrativa, promocional o comercial, ya sea promocionando su establecimiento o prestaciones. Se exceptúa la finalidad promocional o comercial ilícita aquellos Usuarios que hayan sido autorizados previa y expresamente por Zenvi.</p>
        
        <h2>CONTENIDO DEL USUARIO</h2>
        
        <p>En relación con los contenidos que pueda facilitar el Usuario a través de la Plataforma, incluyendo pero sin limitarse a ello, cualquier contenido, derecho de propiedad intelectual y/o industrial, información, documentación, estudio o material, (tales como, a título enunciativo que no limitativo, imágenes, facturas, música, videos, documentos, datos, anuncios, promociones, logos, opiniones, artículos, materiales, links, código, software, inter alia) (el "Contenido del Usuario"), Zenvi se limitará a ser un proveedor de Servicios de intermediación y, por consiguiente, el Contenido del Usuario ha sido facilitado, incluido, transmitido o subido a la Plataforma, bajo su sola y entera responsabilidad y control.</p>
        
        <p>A tal efecto, el Usuario manifiesta conocer y acepta que el Contenido de Usuario son creados, alojados, publicados, compartidos y modificados por el propio Usuario de manera libre e independiente y bajo su sola responsabilidad. Zenvi se limita exclusivamente a poner a disposición del Usuario los medios técnicos necesarios para que puedan llevar a cabo las acciones correspondientes.</p>
        
        <p>Adicionalmente, el Usuario garantiza a Zenvi que cuenta con los derechos necesarios sobre el Contenido del Usuario a los efectos de dar cumplimiento a lo dispuesto en estas Condiciones y, en particular, que cuenta como todos y cada uno de los derechos de propiedad intelectual, industrial, de imagen o de la naturaleza que corresponda necesarios para incluirlos, crearlos, alojarlos publicarlos, compartirlos, modificarlos o cualquier otro, a través de la Plataforma, y todo ello de conformidad con lo previsto en esta Condición Legal y, por consiguiente, que las actuaciones anteriores no supondrán en ningún momento una infracción legal, ni vulneración de los derechos de cualquier clase de tercero, ni incumplimiento de obligaciones contraídas con terceros ni constituye competencia desleal. Asimismo, y en particular, el Usuario acepta y garantiza a Zenvi los siguientes aspectos:</p>
        
        <ul>
          <li>Que es titular legal u ostenta título válido sobre el Contenido del Usuario y que, en caso de que sean titularidad de terceros, han obtenido previamente el consentimiento preciso para su publicación y utilización conforme a estas Condiciones;</li>
          <li>Que ha recabado todas las autorizaciones que sean necesarias y/o convenientes en relación con el Contenido del Usuario ajeno a su propiedad, a los efectos de cumplir con lo dispuesto en las presentes Condiciones;</li>
          <li>El Contenido del Usuario no vulneran la normativa vigente en materia de derecho al honor, a la propia imagen y/o a la intimidad de terceros ni la normativa vigente en materia de derechos de propiedad intelectual e industrial de terceros.</li>
        </ul>
        
        <p>Asimismo, el Usuario se compromete a verificar previamente a su publicación o puesta a disposición en la Plataforma que el Contenido del Usuario puede ser lícitamente reproducido, publicado y usado conforme a estas Condiciones Legales. En el supuesto de que existan dudas acerca de la difusión del Contenido del Usuario, Zenvi recomienda al Usuario que evite emplear dicho Contenido de Usuario. Por otro lado, usted podrá y deberá denunciar cualquier irregularidad o infracción que detecte en relación con el Contenido del Usuario y/o con la identidad y/o veracidad del mismo.</p>
        
        <p>Por todo ello, Zenvi no asume responsabilidad alguna en relación con el Contenido del Usuario. En particular, Zenvi no confirma ni garantiza la identidad ni veracidad de Contenido del Usuario ni de sus datos por ellos creados ni de sus datos o características, ni de sus relaciones con otros usuarios de la Plataforma o Clientes, ni de las relaciones comerciales que subyacen de todo lo anterior.</p>
        
        <p>A tal efecto, Usted se compromete a indemnizar, defender y mantener indemne a Zenvi de cualesquiera reclamaciones, daños y/o perjuicio que puedan causar el Contenido del Usuario, tanto a Zenvi, incluyendo sus socios, administradores, empleados y/o colaboradores, como a terceros u otros usuarios de la Plataforma o a cualquier persona que utilice la Plataforma.</p>
        
        <p>En el supuesto en el que Zenvi considere que el Contenido del Usuario está incumpliendo o vulnerando o generando un quebrantamiento de la seguridad sobre derechos de propiedad intelectual y/o industrial de terceros, estas Condiciones (o cualquier otro término aplicable) o la Política de Privacidad, Zenvi podrá suspender su Cuenta y el acceso y uso de la Plataforma y los Servicios.</p>
        
        <p>La Plataforma podrá mostrar el Contenido del Usuario, en los términos que consideremos oportunos. En particular, Zenvi utiliza la información y el Contenido del Usuario a los efectos de mejorar la Plataforma y los Servicios prestados a través de la misma. Por ello, Zenvi necesita almacenar y distribuir el Contenido del Usuario y la información en centros de datos y sistemas alrededor del mundo, incluyendo fuera del territorio de residencia de Zenvi. Dicha infraestructura puede ser de propiedad de Zenvi y/u operada o de titularidad de otros proveedores de servicios, colaboradores o empresas afiliadas de Zenvi.</p>
        
        <h2>CONTENIDO DE TERCERAS PARTES</h2>
        
        <p>En el supuesto en el que la Plataforma contenga cualquier tipo de información, contenido, material, dato, opinión, promoción, logo o link a cualquier página web, software, aplicación móvil, tecnología o cualquier otro contenido de una tercera parte (de forma conjunta, el "Contenido de Terceros"), Zenvi informa a través de las presentes Condiciones que Zenvi en ningún caso se hace responsable de dicho Contenido de Terceros, ni de cualesquiera modificaciones que se puedan realizar del mismo, y no se hace responsable de la prestación del servicio y/o envío del producto, estableciéndose la relación jurídica entre el Usuario y la empresa o comerciante directa y exclusivamente. Por tanto, el Usuario remitirá cualquier reclamación directamente a la empresa o comerciante tercero, sin perjuicio de poder denunciar ese hecho a Zenvi para su toma en consideración, en su caso.</p>
        
        <p>En este sentido, el Contenido de Terceros puede contener sus propios términos y condiciones de uso, su política de privacidad y/o su política de cookies, los cuales aplicará y regularán la relación entre la persona que utilice dicho Contenido de Terceros y el tercero y, por consiguiente, en ningún caso se regulará por las presentes Condiciones.</p>
        
        <p>La existencia de enlaces en modo alguno implica que Zenvi tenga conocimiento efectivo o de ningún otro tipo de los servicios y contenidos de los mismos en caso de ilicitud, ni que mantenga necesariamente acuerdos con los responsables o titulares de los Sitios Enlazados. Tampoco comporta la existencia de dichos enlaces (links) recomendación, promoción, identificación o conformidad de Zenvi con las manifestaciones, contenidos o servicios proveídos a través de los Sitios Enlazados. En consecuencia, Zenvi no se hace responsable del contenido de los Sitios Enlazados, ni de sus condiciones de uso y políticas de confidencialidad, siendo el Usuario el único responsable de comprobarlos y aceptarlos cada vez que acceda y use los mismos.</p>
        
        <h2>PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
        
        <p>Zenvi por sí, como titular originario o derivativo, como cesionaria o licenciataria, es titular de todos los derechos de propiedad intelectual e industrial de su página web, estén registrados o no, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de Zenvi o bien de sus licenciantes o cedentes.</p>
        
        <p>El uso de la Página por el Usuario en modo alguno supone la cesión de ningún derecho de propiedad intelectual y/o industrial sobre la Página, sus contenidos y/o los signos distintivos de Zenvi. A tal efecto, mediante las presentes Condiciones Legales quedan expresamente reservados todos los derechos y, salvo en aquellos supuestos en los que esté legalmente permitido, queda expresamente prohibido al Usuario, entre otros actos, la reproducción, transformación, distribución, comunicación pública, puesta a disposición, extracción y/o reutilización de la Página, sus contenidos y/o los signos distintivos de Zenvi, salvo autorización previa y por escrito de Zenvi.</p>
        
        <p>El Usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de Zenvi. Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El Usuario deberá abstenerse de suprimir, alterar, eludir o manipular los derechos de propiedad intelectual e industrial, así como el "copyright" y demás datos identificativos de los derechos de sus titulares incorporados a los contenidos de la Página, así como cualquier dispositivo de protección o sistema de seguridad o "DRM" que estuviera instalado en las páginas de Zenvi.</p>
        
        <h2>OBLIGACIONES DE LOS USUARIOS</h2>
          <p>El Usuario se obliga a utilizar la Plataforma de conformidad con las presentes Condiciones Legales con plena observancia y respeto de la legislación aplicable y de los derechos de terceros asumiendo, además, las siguientes obligaciones:</p>
          <ul>
            <li>Únicamente creará y utilizará una cuenta de Usuario y un único nombre de Usuario, que será público para toda la Plataforma e identificará a todos los efectos su operativa en él. La Cuenta de Usuario será gestionable sólo mediante aquellas claves de acceso o cualesquiera otros recursos de seguridad que estén disponibles en la Plataforma. El Usuario deberá crear y mantener en confidencialidad dicha clave o elementos de seguridad.</li>
            <li>Preservará la confidencialidad de las claves de acceso y cualesquiera otros elementos que permitan la suplantación de su identidad y, en caso de que considere que se ve amenazada, lo comunicará sin demora a Zenvi y procederá a su actualización o sustitución inmediata para evitar utilizaciones no autorizadas de su nombre o Cuenta de Usuario.</li>
            <li>Omitirá el empleo de cualquier dato, información o Contenido del Usuario accesible en la Plataforma para fines distintos del uso de la Plataforma según lo previsto en las presentes Condiciones.</li>
            <li>Se abstendrá de dirigir cualquier clase de oferta o propuesta comercial a los usuarios de la Plataforma sin perjuicio de las comunicaciones relativas a la operativa administrativa propia de las facturas correspondientes y salvo que se trate de una empresa o comerciante y disponga de la autorización contractual específica por parte de Zenvi.</li>
            <li>Asumirá plena responsabilidad por cualesquiera actuaciones y Contenido del Usuario efectuados desde o atribuibles a su nombre o Cuenta de usuario por él creados, salvo que hubiera comunicado con la suficiente antelación a Zenvi una incidencia que ponga en riesgo la confidencialidad de su clave de acceso, haya adoptado las medidas necesarias para prevenir su uso no autorizado conforme a estas Condiciones Legales y existan indicios claros de que dicha actuación no ha sido llevada a cabo por el Usuario.</li>
            <li>Suministrará a Zenvi y al resto de usuarios de la Plataforma siempre datos e informaciones ciertos, auténticos y legítimos.</li>
            <li>No utilizará la Plataforma para efectuar actividades contrarias a las leyes, a la moral o al orden público. En concreto, y a mero título enunciativo, se abstendrá de:
              <ul>
                <li>Llevar a cabo actuaciones contrarias a la legislación sobre blanqueo de capitales o terrorismo;</li>
                <li>Realizar cualesquiera actividades que contravengan o atenten contra derechos fundamentales y libertades públicas reconocidas constitucionalmente, en los tratados internacionales a los que España se haya adherido o en el resto del ordenamiento jurídico;</li>
                <li>Falsear los datos facilitados a través de la Plataforma de manera que pueda constituir un delito de estafa, falsedad documental, apropiación indebida, etc.;</li>
                <li>Simular relaciones comerciales que no se han producido en el tráfico aptas para constituir alguno de los delitos mencionados en el apartado anterior u otros delitos o faltas;</li>
                <li>Revelar secretos empresariales o comerciales de otros usuarios o de Clientes o información confidencial de éstos que le haya sido suministrada en el contexto de su relación en la Plataforma;</li>
                <li>Realizar cualesquiera comunicaciones constitutivas de actos de publicidad ilícita, engañosa o desleal y, en general, de competencia desleal o de violación del derecho al honor y a la propia imagen;</li>
                <li>Omitirá el empleo de medios o instrumentos técnicos o informáticos que pudieran impedir o dificultar el normal funcionamiento de la Plataforma o dañar las herramientas y dispositivos técnicos integrados en la Plataforma u otros pertenecientes a Zenvi a al resto de usuarios de la Plataforma; y</li>
                <li>Omitirá el empleo de cualquier herramienta técnica o informática que permita la extracción, automatizada o sustancial, de datos o contenidos alojados o almacenados en o accesibles por medio de la Plataforma.</li>
              </ul>
            </li>
          </ul>
          <p>En caso de incumplimiento de las obligaciones establecidas, Zenvi se reserva el derecho de suspender o cancelar el acceso del Usuario a la Plataforma, además de exigir la reparación de los daños y perjuicios causados por dicho incumplimiento.</p>

          <h2>EXCLUSIÓN DE OBLIGACIONES Y GARANTÍAS</h2>
          <p>Zenvi no asume obligación contractual alguna con el Usuario distinta a la derivada de las presentes Condiciones Legales ni presta garantía alguna al Usuario y, en particular, declina toda responsabilidad por, entre otros:</p>
          <ul>
            <li>El funcionamiento correcto, continuo o ininterrumpido de la Plataforma, que Zenvi podrá cesar en cualquier momento a su discreción sin perjuicio del respeto a los compromisos contractuales específicamente asumidos a cambio de una contraprestación económica. De la misma manera, Zenvi no garantiza la compatibilidad de la Plataforma con los dispositivos de acceso del Usuario, ya sean estos ordenadores, navegadores, dispositivos móviles o de otro tipo. El Usuario declara ser consciente de que internet es un medio sujeto a disfunciones e interrupciones de servicio;</li>
            <li>La adecuación de la Plataforma a las necesidades o expectativas del Usuario;</li>
            <li>El nivel de satisfacción, ganancia o rentabilidad o la ausencia de pérdidas o daños como consecuencia de la utilización por el Usuario de la Plataforma ni las consecuencias patrimoniales, adversas o positivas, que pueda experimentar el Usuario a resultas de la información intercambiada en la Plataforma;</li>
            <li>La veracidad, exactitud y vigencia de los datos correspondientes a al resto de usuarios de la Plataforma, el Contenido del Usuario o el Contenido de Terceros publicados o compartidos en la Plataforma ni, en particular, de las relaciones comerciales, profesionales o laborales del Usuario con el Cliente o cualquier tercero;</li>
            <li>La solvencia, fiabilidad técnica, comercial, moral o financiera del Usuario o de los Clientes;</li>
            <li>La licitud, exactitud, veracidad, y vigencia del Contenido del Usuario o de la actuación del Usuario en la Plataforma;</li>
            <li>La exactitud de la información facilitada por empresarios o comerciantes o cualquier tercero respecto de los productos y/o servicios que ofrezcan a través de la Plataforma y los Contenidos de Terceros;</li>
            <li>La conclusión, buen fin o regularidad de los contratos o acuerdos convenidos entre el Usuario con las empresas o comerciantes participantes en la Plataforma o fuera de ella;</li>
            <li>El cumplimiento de la normativa tributaria, administrativa, societario, laboral o cualquier otra, incluyendo en materia de expedición y conservación de facturas, que incumbirá exclusivamente al Proveedor, Clientes y Usuario, sin que Zenvi garantice en modo alguno que la utilización de la Plataforma exima del cumplimiento de esa normativa o sea adecuado para esa finalidad;</li>
            <li>El almacenamiento adecuado, la preservación o no pérdida del Contenido del Usuario. En particular, Zenvi no garantiza:
              <ul>
                <li>La conservación o almacenamiento del Contenido del Usuario (tales como facturas, comentarios, comunicaciones, estados de las facturas, etc.) alojados en la Plataforma;</li>
                <li>La conservación o almacenamiento del Contenido del Usuario ni de las facturas de acuerdo con los requisitos exigidos por la normativa aplicable (mercantil, contable, fiscal o de otro tipo); o</li>
                <li>La conservación o almacenamiento del Contenido de Usuario durante un periodo mínimo ni por el plazo exigido por la normativa aplicable (mercantil, contable, fiscal o de otro tipo) de conservación de los documentos justificativos de la facturación.</li>
              </ul>
            </li>
          </ul>
          <p>Cada Usuario deberá tomar las medidas oportunas para mantener, conservar y almacenar la documentación oportunamente, sin que Zenvi asuma ningún tipo de responsabilidad a tal efecto.</p>
          <ul>
            <li>La actuación inadecuada del Usuario o el resto de usuarios de la Plataforma o Clientes en relación con la información que pueda habérseles facilitado por otros usuarios, por ejemplo, aunque no exclusivamente, en el Área de Relación (incluyendo, información confidencial o secretos empresariales);</li>
            <li>Zenvi no verifica la existencia de poderes de representación ni la suficiencia de los existentes para que el Usuario pueda actuar por cuenta de una empresa, ni tampoco la existencia o identidad del Usuario. Por lo tanto, cada Usuario deberá llevar a cabo un uso diligente y prudente de la Plataforma interactuando exclusivamente con usuarios y Clientes que, como en el mundo fuera de línea, merezcan su confianza. La información sobre un usuario de la Plataforma (su antigüedad, la información de su perfil, las Áreas de Relación que tenga con otros negocios y las experiencias que se publiquen con ese usuario) podrá ser de ayuda a ese respecto, pero no exime al Usuario de observar la debida diligencia y de usar sus propios mecanismos de verificación; y</li>
            <li>Zenvi no estará obligada a emitir certificados a favor del Usuario o de cualquier tercero sobre los hechos o circunstancias acaecidos en la Plataforma o sobre el Contenido del Usuario o Contenidos de Terceros en ella alojados, salvo que sea requerida en tal sentido por las autoridades competentes o que, por las circunstancias aplicables, así lo considere oportuno, a su libre discreción. A tal efecto, el Usuario no podrá reclamar nada contra Zenvi por las consecuencias que, para ellos o terceros, pueda tener la emisión de esos certificados.</li>
          </ul>
          <p>Asimismo, se deja constancia expresa que la Plataforma y toda la información, productos y servicios prestados o contenidos en los mismos están disponibles y desarrollados «as is» y de la forma en la que estén disponibles en cada momento «as available». Por consiguiente, Zenvi de forma expresa no garantiza al Usuario ni a cualquier otra persona o entidad, de forma expresa o implícita, que los mismos se adaptarán a cualquier finalidad o uso concreto, ni será completo, útil ni adecuado para la actividad del Usuario o de cualquiera de los usuarios de la Plataforma, incluyendo, de forma enunciativa pero no limitativa, garantía de comerciabilidad, patentabilidad y/o adecuación a un determinado propósito, ni de existencia de defectos o errores, ni que tienen capacidad de integrarse en un sistema determinado, o de no infracción de ninguna patente u otros derechos de propiedad intelectual y/o industrial de terceros.</p>
          <p>Por último, el Usuario manifiesta y reconoce que Zenvi no será en ningún caso responsable de cualesquier actuaciones, daños, sanciones, inspecciones, reclamaciones, pérdidas o cualquier otro que se pudiesen derivar para el Usuario en el marco de la remisión o envío por parte de Zenvi a cualesquier administradores públicas, de las facturas emitidas o enviadas por parte del Usuario a sus Clientes a través de la Plataforma o de cualquier dato o información contenida en las mismas.</p>
          <p>De conformidad con todo lo anterior, zenvi no será responsable, en ningún caso, por cualesquiera daños, pérdidas, costes, perjuicios, ya sean de forma directa o indirecta (incluyendo, pero sin limitarse al lucro cesante, daño emergente o daño punitivo), así como a cualquier otro daño que se pueda causar o generar como consecuente del acceso y/o uso de la plataforma, incluyendo el contenido de todo ello. En el supuesto en el que las limitaciones a la responsabilidad previstas en la presente cláusula no pudieses ser de aplicación de conformidad con la legislación aplicable en cada momento, en ningún caso la responsabilidad agregada de zenvi que se pueda llegar a derivar, en su caso, del acceso, participación o uso de la plataforma podrá superar el importe de las pérdidas que de forma razonable se hayan generado y, en ningún caso, superará la menor de las siguientes cantidades (i) 100€; o (ii) la cantidad abonada por parte del usuario a zenvi en los últimos seis meses por la utilización de la plataforma. En el supuesto en el que de conformidad con la legislación aplicable no sea posible aplicar todo o parte de la limitación anterior, la limitación se extenderá a la limitación más extensa permitida por la legislación aplicable.</p>

          <h2>NOTIFICACIÓN DE INFRACCIONES</h2>
          <p>En el caso de que el Usuario considere que existen hechos o circunstancias que revelen el carácter ilícito de la utilización de la Plataforma por otro usuario o de un Contenido de Usuario o Contenido de Terceros (incluyendo la violación de derechos de propiedad intelectual o industrial, la realización de actos de competencia desleal o publicidad ilícita, actos contrarios al derecho al honor, a la intimidad o a la propia imagen o a la legislación fiscal, de blanqueo de capitales o terrorismo, etc.) deberá enviar, bajo su responsabilidad y compromiso de que la información proporcionada es exacta y veraz, una notificación a Zenvi en la que se contengan los siguientes extremos: (i) datos personales que permitan la identificación y el contacto del reclamante; y (ii) especificación de la supuesta actividad ilícita llevada a cabo en la Plataforma y, cuando se trate de una supuesta violación de derechos de propiedad intelectual o industrial, indicación precisa y concreta de los derechos infringidos y de los hechos infractores así como de su localización en la Plataforma. Estas notificaciones deberán ser enviadas a info@Zenvi.eu e indicarán: "Denuncia de Usuario". Sin perjuicio de lo anterior, Zenvi puede poner a su disposición, si así lo decide, otros mecanismos para llevar a cabo estas notificaciones.</p>
          <p>Una vez recibida dicha Denuncia de Usuario, Zenvi llevará a cabo, con la debida celeridad en atención a las circunstancias aplicables, las actuaciones oportunas que le correspondan según sus obligaciones legales y según estas Condiciones, a su libre discreción, incluyendo la puesta en conocimiento de los hechos ante las autoridades competentes.</p>

          <h2>POLÍTICA DE PROTECCIÓN DE DATOS Y COOKIES</h2>
          <p>Puede consultar nuestra política de privacidad <a href="https://zenvi.es/privacy-policy">aquí</a> y nuestra política de cookies <a href="https://zenvi.es/cookies-policy">aquí</a>.</p>

          <h2>DURACIÓN DEL SERVICIO</h2>
          <p>La prestación del Servicio por parte de Zenvi tiene, en principio, una duración indefinida. No obstante, Zenvi está autorizada para dar por terminada y/o suspender en todo o en parte la prestación de los Servicios en cualquier momento, sin perjuicio del respeto a los compromisos contractuales específicamente asumidos de forma previa a dicha terminación y/o resolución a cambio de una contraprestación económica que, de existir, determinarán la aplicación del plazo de preaviso acordado o, en su defecto, de un preaviso razonable (que, en ningún caso, será superior a 30 días). Salvo en supuestos de fuerza mayor, caso fortuito u otro tipo de urgencias, Zenvi advertirá la terminación del servicio con la antelación suficiente para que el Usuario puedan adoptar, de manera diligente y a su coste e iniciativa, las medidas necesarias para obtener copia o recuperar el Contenido del Usuario.</p>
          <p>Por otro lado, el Usuario podrá suspender o terminar el uso de los Servicios en cualquier momento, de conformidad con lo dispuesto en las presentes Condiciones.</p>

          <h2>COMUNICACIONES</h2>
          <p>Toda comunicación entre las Partes deberá hacerse por escrito, sea por correo, telefax o correo electrónico enviados, en el caso de Zenvi, a las direcciones indicadas en las presentes Condiciones Legales. En el caso del Usuario, Zenvi podrá remitir las comunicaciones a los datos de contacto que este haya facilitado en el proceso de registro en la Plataforma y también mediante la publicación de un aviso en forma de banner en la Plataforma o de otro tipo para avisar de determinados cambios. Se considerarán debidamente entregadas y recibidas las comunicaciones efectuadas por carta con acuse de recibo y las efectuadas por telegrama, telefax o correo electrónico cuando pueda acreditarse su recepción. Toda comunicación enviada a la dirección facilitada por el Usuario se entenderá correctamente efectuada, excepto si el Usuario hubiera previamente notificado a Zenvi un cambio de dirección con una antelación mínima de 15 días hábiles.</p>

          <h2>MODIFICACIÓN DE LA RELACIÓN CONTRACTUAL</h2>
          <p>El Usuario acepta, reconoce y consiente que Zenvi podrá modificar las presentes Condiciones Legales en cualquier momento El Usuario deberá consultar periódicamente las Condiciones Legales para conocer su versión aplicable en cada momento. No obstante, Zenvi se compromete a notificar al Usuario sobre cualquier modificación significativa en las Condiciones Legales con un plazo razonable de antelación antes de que los cambios entren en vigor. Esta notificación podría realizarse a través de correo electrónico o mediante un aviso destacado en la Plataforma.</p>

          <h2>MISCELÁNEA</h2>
          <p>Las presentes Condiciones Legales son la manifestación expresa de la voluntad de las Partes en relación con su objeto e invalidan los acuerdos verbales y escritos alcanzados al respecto con anterioridad a su aceptación. En caso de discrepancia entre las versiones de las Condiciones Legales, Política de Privacidad y Cookies y cualquier otra documentación en los diferentes idiomas en los que hayan sido facilitadas por Zenvi, prevalecerá su versión en español, siempre que sea legalmente posible. La declaración de nulidad o invalidez de cualquier cláusula contenida en las presentes Condiciones Legales no afectará a la validez y eficacia de las demás cláusulas, que se interpretarán y aplicarán en el sentido lícito más próximo al que se derivase de la aplicación de la cláusula nula. La renuncia por alguna de las Partes al cumplimiento de cualquiera de las disposiciones de estas Condiciones Legales por la otra Parte, no constituirá una renuncia al derecho a reclamar el cumplimiento del resto de disposiciones de estas Condiciones Legales o de cualquier otra obligación. El retraso o la omisión de las Partes en el ejercicio de algún derecho que le correspondiera por virtud de las Condiciones Legales tampoco constituirán una renuncia al derecho correspondiente.</p>

          <h2>DATOS IDENTIFICATIVOS</h2>
          <p>JIRO SIMPLIFIED BILLS, S.L. es una sociedad de responsabilidad limitada y nacionalidad española, que dispone de NIF B70653878 y está inscrita en el Registro Mercantil de Madrid, hoja M-808033, tomo 45985, folio 145.</p>
          <p>Los datos de contacto de JIRO SIMPLIFIED BILLS, S.L. son los siguientes:</p>
          <ul>
            <li>Domicilio: Calle Príncipe de Vergara 94, escalera B, 8º Izquierda, 28006 Madrid</li>
            <li>Dirección de correo electrónico: hola@clienteszenvi.es</li>
          </ul>

          <h2>LEY APLICABLE Y JURISDICCIÓN</h2>
          <p>Las partes acuerdan someterse a los jueces y tribunales de la ciudad de Madrid, España, para resolver cualquier disputa sobre la validez, eficacia, cumplimiento, interpretación, ejecución o cualquier otra cuestión relativa a las condiciones. En el caso de consumidores que residen en otros Estados miembros de la Unión Europea, la determinación de la legislación y jurisdicción aplicable se realizará conforme a las normativas de protección al consumidor de la UE, que pueden prever la aplicación de la legislación del país de residencia del consumidor para garantizar una protección adecuada.</p>
      </div>
    </article>
  );
};

export default tandCs;
