import { Link } from 'react-router-dom';
import { motion } from 'motion/react';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';

import { Icons, Icon } from '../../Icon';
import styles from './RationaleCTA.module.css';

const RationaleCTA = () => {
  const { t } = useTranslations();
  return (
    <motion.section
      className={styles.root}
      initial={{ opacity: 0, y: 32 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
      viewport={{ once: true, amount: 0.5 }}
    >
      <div className={styles.box}>
        <span className={`${styles.alignRight} ${styles.text}`}>{t('landing:rationaleCTA:text:1')}</span>
        <Link to={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SIGN_UP}`} className={styles.title}>
          <CTA />
        </Link>
        <span className={styles.text}>{t('landing:rationaleCTA:text:2')}</span>
      </div>

    </motion.section>
  );
};

const CTA = () => (
  <motion.div
    initial={{ scale: 0.9 }}
    whileInView={{ scale: 1, transition: { duration: 0.5, delay: 0.1 } }}
    viewport={{ once: true, amount: 0.5 }}
  >
    <div className={styles.icon}>
      <Icon size={64} name={Icons.Switch} />
    </div>
  </motion.div>
);

export default RationaleCTA;
