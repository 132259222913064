import { Header } from './Header';
import { Hero } from './Hero';
import { RationaleWhat } from './RationaleWhat';
import { RationaleHow } from './RationaleHow';
import { RationaleSteps } from './RationaleSteps';
import { RationaleCTA } from './RationaleCTA';
import { Footer } from './Footer';
import { CookieConsent } from './CookieConsent';
import styles from './Landing.module.css';

const Landing = () => {
  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <Header />
          <main>
            <Hero />
            <RationaleWhat />
            <RationaleHow />
            <RationaleSteps />
            <RationaleCTA />
          </main>
          <Footer />
        </div>
      </div>
      <CookieConsent />
    </>
  );
};

export default Landing;
