import styles from './LegalPolicies.module.css';

const PrivacyPolicy = () => {
  return (
 <article className={styles.root}>
 <header>
 <h1>POLÍTICA DE PRIVACIDAD</h1>
 </header>
 <div className={styles.content}>
 <p>Nuestra Política de Privacidad se basa en el Reglamento (UE) 2016/679 General de Protección de Datos del Parlamento Europeo y del Consejo, de 27 de abril de 2016.</p>
 
 <h2>NUESTROS DATOS DE CONTACTO</h2>
 
 <p>JIRO SIMPLIFIED BILLS S.L. (Zenvi).</p>
 
 <p>Calle Príncipe de Vergara 94, escalera B, 8º Izquierda.</p>
 
 <p>28006 Madrid.</p>
 
 <p>Email: <a href="mailto:lopd@zenvi.es">lopd@zenvi.es</a>.</p>
 
 <h2>¿PARA QUÉ UTILIZAMOS LOS DATOS PERSONALES?</h2>
 
 <h3>Para prestar nuestros servicios</h3>
 
 <p>Como responsables del tratamiento, utilizamos los datos personales identificativos, de contacto y económico-financieros de nuestros Usuarios para la prestación de nuestros servicios.</p>
 
 <p>Nuestros Usuarios pueden ver, analizar y comparar sus facturas / contratos contra los de otros proveedores de suministros (luz, gas, telefonía, etc.) y realizar cambios de proveedores.</p>
 
 <p>Las facturas que facilitan los Usuarios en Zenvi, y todos los datos asociados a las mismas (por ejemplo, importes, fechas, plazos de vencimiento, aceptación o realización de observaciones, pagos/cobros, etc.) se almacenan en nuestra plataforma, formando parte de nuestros servicios el guardar dichos datos.</p>
 
 <p>También utilizamos los datos bancarios y otra información necesaria que el propio Usuario nos facilita para extraer la información necesaria para prestar nuestros servicios.</p>
 
 <h3>Enviarle comunicaciones comerciales</h3>
 
 <p>En calidad de cliente y como consecuencia de la legitimación otorgada por el artículo 21 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico, desde Zenvi, le podremos enviar publicidad acerca de nuestros productos o servicios relacionados con el servicio contratado por usted. En todo caso, puede optar por oponerse al tratamiento de sus datos con fines promocionales mediante el envío de un correo electrónico a la dirección lopd@zenvi.es.</p>
 
 <h2>¿CON QUÉ BASES JURÍDICAS TRATAMOS LOS DATOS PERSONALES?</h2>
 
 <p>Tratamos datos personales siempre que se cumpla al menos una de las siguientes condiciones:</p>
 
 <ol>
 <li>Que el interesado nos haya dado su consentimiento.</li>
 <li>Que sea necesario para prestar nuestros servicios al Usuario.</li>
 <li>Que sea necesario para el cumplimiento de nuestras obligaciones legales.</li>
 </ol>
 
 <h2>¿A QUÉ POSIBLES DESTINATARIOS PODEMOS COMUNICAR LOS DATOS PERSONALES?</h2>
 
 <p>Únicamente compartimos los datos personales de nuestros Usuarios con los siguientes destinatarios, en calidad de encargados del tratamiento:</p>
 
 <ul>
 <li>Nuestros proveedores tecnológicos, por ejemplo, para el mantenimiento de nuestros sistemas o para la prestación del servicio de lectura de facturas.</li>
 <li>Las entidades colaboradoras que prestan servicios a los Usuarios de Zenvi.</li>
 <li>Cualquier autoridad administrativa o judicial competente en prevención de blanqueo de capitales y financiación del terrorismo en cumplimiento de la Ley de Prevención del Blanqueo de Capitales, con el fin de prevenir cualquier operación relacionada con las mismas.</li>
 <li>Cualquier otra autoridad competente (judicial o administrativa) prevista en la ley.</li>
 </ul>
 
 <h2>¿DURANTE CUÁNTO TIEMPO CONSERVAMOS LOS DATOS?</h2>
 
 <p>La empresa tratará los datos personales mientras dure la relación contractual con el interesado o, en su caso, mientras exista un consentimiento válido otorgado por el mismo. Una vez finalizado el tratamiento, los datos se conservarán debidamente bloqueados durante el tiempo necesario para atender posibles responsabilidades legales derivadas del tratamiento, haciéndolos disponibles solo para las administraciones públicas competentes, jueces y tribunales.</p>
 
 <h2>¿QUÉ MEDIDAS DE SEGURIDAD APLICAMOS?</h2>
 
 <p>Aplicamos medidas técnicas y organizativas para garantizar el nivel de seguridad adecuado de conformidad con la normativa aplicable.</p>
 
 <h2>¿QUÉ MEDIDAS DE SEGURIDAD APLICAMOS PARA EL USO DE LA INTELIGENCIA ARTIFICAL?</h2>
 
 <p>Podemos utilizar tecnología de inteligencia artificial, como chatbots apoyados por grandes modelos lingüísticos, como parte de nuestra operativa. Al hacerlo, mantenemos un control total sobre tus datos y garantizamos que no se compartirán con terceros con el fin de entrenar modelos de IA.</p>
 
 <h2>¿QUÉ DERECHOS TIENES Y CÓMO EJERCERLOS?</h2>
 
 <p>Puedes solicitar el acceso a tus datos personales, su rectificación o supresión, portabilidad, o la limitación de su tratamiento, o puedes oponerte al tratamiento, dirigiéndote a Zenvi (utilizando los datos de contacto que te facilitamos al principio de esta Política de Privacidad).</p>
 
 <p>En cuanto a tratamientos basados en el consentimiento, puedes retirar tu consentimiento en cualquier momento. Asimismo, tienes derecho a reclamar ante la Agencia Española de Protección de Datos (<a href="http://www.aepd.es">www.aepd.es</a>)</p>
 
 <h2>REVISIÓN Y ACTUALIZACIÓN</h2>
 
 <p>La presente Política de Privacidad se revisa y actualiza anualmente.</p>
 </div>
 </article>
  );
 };

export default PrivacyPolicy;
