import styles from './Callout.module.css';

const Callout = ({ type = 'info', title, text }) => {
  return (
    <div className={`${styles.root} ${styles[type]}`}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className={styles.icon}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 12C22 6.47703 17.523 2.00003 12 2.00003C6.477 2.00003 2 6.47703 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12ZM12 7.00003C12.2652 7.00003 12.5196 7.10539 12.7071 7.29292C12.8946 7.48046 13 7.73481 13 8.00003V13C13 13.2652 12.8946 13.5196 12.7071 13.7071C12.5196 13.8947 12.2652 14 12 14C11.7348 14 11.4804 13.8947 11.2929 13.7071C11.1054 13.5196 11 13.2652 11 13V8.00003C11 7.73481 11.1054 7.48046 11.2929 7.29292C11.4804 7.10539 11.7348 7.00003 12 7.00003ZM11 16C11 15.7348 11.1054 15.4805 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15H12.008C12.2732 15 12.5276 15.1054 12.7151 15.2929C12.9026 15.4805 13.008 15.7348 13.008 16C13.008 16.2652 12.9026 16.5196 12.7151 16.7071C12.5276 16.8947 12.2732 17 12.008 17H12C11.7348 17 11.4804 16.8947 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16Z"
          fill="currentcolor"
        />
      </svg>
      <div className={styles.content}>
        <p className={styles.heading}>{title}</p>
        {text ? <p className={styles.text}>{text}</p> : null}
      </div>
    </div>
  );
};

export default Callout;
