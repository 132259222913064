import styles from './RadioButton.module.css';

const RadioButton = ({ field: { name, value, onChange }, id, label, className, disabled = false, ...props }) => {
  return (
    <div className={styles.root}>
      <input
        name={name}
        id={id}
        type="radio"
        value={id}
        checked={id === value}
        onChange={onChange}
        className={styles.input}
        disabled={disabled}
        {...props}
      />
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
