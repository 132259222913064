import { motion } from 'motion/react';

import { ROUTES } from '../../../routes';
import useTranslations from '../../../i18n/useTranslations';

import { Button } from '../../Button';
import hero from '../assets/landing-hero.jpg';
import styles from './Hero.module.css';

const IconLight = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#FF9344" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5264 10.61C17.5264 10.1503 17.2802 9.75481 16.8988 9.58415C16.4987 9.40513 16.0223 9.51203 15.7262 9.91014L11.7276 15.3C11.4664 15.6519 11.4493 16.0882 11.5788 16.4289C11.7076 16.7675 12.0285 17.1094 12.5138 17.1094L14.4406 17.11C14.4538 17.1256 14.4731 17.1622 14.4731 17.218V21.39C14.4731 21.8497 14.7193 22.2453 15.1007 22.4159C15.5008 22.595 15.9773 22.4881 16.2733 22.09L20.2722 16.7004C20.5332 16.3482 20.55 15.9117 20.4203 15.571C20.2913 15.2324 19.9703 14.8907 19.4851 14.8907L17.559 14.8901C17.5457 14.8744 17.5264 14.8379 17.5264 14.782V10.61Z"
        fill="white"
      />
    </svg>
  );
};
const IconGas = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#6E97FF" />
      <path
        d="M20.5328 15.3357C20.2295 14.652 19.7885 14.0384 19.2375 13.5331L18.7828 13.1153C18.7674 13.1015 18.7488 13.0917 18.7287 13.0868C18.7086 13.0819 18.6876 13.082 18.6675 13.0871C18.6475 13.0922 18.629 13.1021 18.6137 13.1161C18.5983 13.13 18.5867 13.1475 18.5797 13.167L18.3766 13.7506C18.25 14.1168 18.0172 14.4908 17.6875 14.8585C17.6656 14.882 17.6406 14.8882 17.6234 14.8898C17.6062 14.8913 17.5797 14.8882 17.5562 14.8663C17.5344 14.8475 17.5234 14.8194 17.525 14.7912C17.5828 13.8492 17.3016 12.7867 16.6859 11.6304C16.1766 10.6696 15.4687 9.92007 14.5844 9.39743L13.9391 9.01719C13.8547 8.96712 13.7469 9.03284 13.7516 9.13142L13.7859 9.88251C13.8094 10.3958 13.75 10.8495 13.6094 11.2267C13.4375 11.6883 13.1906 12.117 12.875 12.5019C12.6553 12.7695 12.4064 13.0114 12.1328 13.2233C11.4739 13.7305 10.9381 14.3805 10.5656 15.1245C10.194 15.875 10.0005 16.7013 10 17.5389C10 18.2775 10.1453 18.9926 10.4328 19.667C10.7104 20.3164 11.111 20.9057 11.6125 21.4024C12.1187 21.9031 12.7062 22.2974 13.3609 22.5713C14.0391 22.856 14.7578 23 15.5 23C16.2422 23 16.9609 22.856 17.6391 22.5728C18.2921 22.3006 18.886 21.9036 19.3875 21.4039C19.8937 20.9032 20.2906 20.318 20.5672 19.6686C20.8542 18.996 21.0015 18.2719 21 17.5405C21 16.7769 20.8437 16.0352 20.5328 15.3357Z"
        fill="white"
      />
    </svg>
  );
};

const Hero = () => {
  const { t } = useTranslations();
  return (
    <section className={styles.root}>
      <motion.img
        src={hero}
        alt=""
        loading="eager"
        className={styles.image}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { duration: 0.4, delay: 0.2 } }}
        viewport={{ once: true }}
      />
      <div className={styles.boxTexts}>
        <motion.p
          className={styles.textIcons}
          initial={{ opacity: 0, y: 4 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.3 } }}
          viewport={{ once: true }}
        >
          <span>Luz</span>
          <IconLight />
          <span>y</span>
          <IconGas />
          <span>Gas</span>
        </motion.p>
        <motion.h1
          initial={{ opacity: 0, y: 4 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.4 } }}
          viewport={{ once: true }}
        >
          {t('landing:hero')}
        </motion.h1>
        <motion.div
          initial={{ opacity: 0, y: 4 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.5 } }}
          viewport={{ once: true }}
        >
          <Button href={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SIGN_UP}`} className={styles.button}>
            Empezar
          </Button>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
