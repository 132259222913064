import { motion } from 'motion/react';
import useTranslations from '../../../i18n/useTranslations';

import styles from './RationaleSteps.module.css';

const RationaleSteps = () => {
  const { t } = useTranslations();
  return (
    <section className={styles.root}>
      <motion.h2
        className={styles.title}
        initial={{ opacity: 0, y: 32 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
        viewport={{ once: true, margin: '-48px' }}
      >
        {t('landing:rationaleSteps:title')}
      </motion.h2>
      <motion.div
        className={styles.box}
        initial={{ opacity: 0, y: 32 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.12 } }}
        viewport={{ once: true, margin: '-48px' }}
      >
        <ul className={styles.list}>
          <li className={styles.item}>
            <span className={styles.itemNumber}>01</span>
            <div className={styles.itemTexts}>
              <h3 className={styles.itemTitle}>{t('landing:rationaleSteps:item:title:1')}</h3>
              <p className={styles.itemDescription}>
                {t('landing:rationaleSteps:item:description:1')}
              </p>
            </div>
          </li>
          <li className={styles.item}>
            <span className={styles.itemNumber}>02</span>
            <div className={styles.itemTexts}>
              <h3 className={styles.itemTitle}>{t('landing:rationaleSteps:item:title:2')}</h3>
              <p className={styles.itemDescription}>
                {t('landing:rationaleSteps:item:description:2')}
              </p>
            </div>
          </li>
          <li className={styles.item}>
            <span className={styles.itemNumber}>03</span>
            <div className={styles.itemTexts}>
              <h3 className={styles.itemTitle}>{t('landing:rationaleSteps:item:title:3')}</h3>
              <p className={styles.itemDescription}>
                {t('landing:rationaleSteps:item:description:3')}
              </p>
            </div>
          </li>
        </ul>
      </motion.div>
    </section>
  );
};

export default RationaleSteps;
