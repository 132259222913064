import { useEffect, useRef, useState } from 'react';
import useTranslations from '../../../i18n/useTranslations';
import { useForm } from '../../../context';
import { Button } from '../../Button';
import { Stepper } from '../Stepper';
import { Dialog } from './Dialog';
import { CheckoutForm } from './CheckoutForm';
import Option from './Option';
import styles from './StripeCheckout.module.css';

const OPTIONS = ['M', 'Y'];
const MONTHLY_PRICE = 2;
const YEARLY_PRICE = (MONTHLY_PRICE * 10) / 12;

const StripeCheckout = () => {
  const { contractResult, checkoutInformation } = useForm();
  const { t, formatNumber } = useTranslations();
  const [selectedOption, setSelectedOption] = useState(OPTIONS[1]);
  const dialogRef = useRef(null);
  
  const handleClick = () => dialogRef.current.showModal();
  const handleCloseDialog = () => dialogRef.current.close();


  useEffect(() => {
      const handleContracts = async () => {
      if (checkoutInformation.update_payment_method_required) {
          handleClick();
      }
      };
  
      handleContracts();
  }, [checkoutInformation]);

  const unsubscribedContracts = contractResult.filter(contract => !contract.contract_subscribed && contract.offer_status !== 'keep_in_the_loop' && contract.ahorro_estimado_anual !== null);
  
  const totalSavings = unsubscribedContracts.reduce((sum, item) => {
    const value = item.ahorro_estimado_anual || 0;
    return sum + value;
  }, 0);

  return (
    <Stepper currentStep={4}>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>
            {t('account_stripe_checkout:heading', { contractsLength: unsubscribedContracts.length })}
          </h1>
          <p className={styles.subheading}>
            {t('account_stripe_checkout:subheading', {
              estimatedSavings: formatNumber(totalSavings)
            })}
          </p>
        </header>
        <div className={styles.content}>
          <div className={styles.optionsWrapper}>
            <Option
              heading={t('general:plan:monthly')}
              price={MONTHLY_PRICE}
              value={OPTIONS[0]}
              selectedOption={selectedOption}
              onChange={() => setSelectedOption(OPTIONS[0])}
            />
            <Option
              heading={t('general:plan:yearly')}
              price={YEARLY_PRICE}
              value={OPTIONS[1]}
              selectedOption={selectedOption}
              onChange={() => setSelectedOption(OPTIONS[1])}
            />
          </div>
          <div className={styles.action}>
            <Button isFullWidth onClick={handleClick}>
              {t('action:go_to_payment')}
            </Button>
          </div>
          <Dialog ref={dialogRef} onClose={handleCloseDialog}>
            <ul className={styles.summary}>
              <li className={styles.summaryItem}>
                {t('general:contracts-length')}
                <span>{unsubscribedContracts.length}</span>
              </li>
              <li className={styles.summaryItem}>
                {t('general:plan')}
                <span>
                  {selectedOption === OPTIONS[0] ? t('general:monthly') : t('general:yearly')}
                </span>
              </li>
              <li className={`${styles.summaryItem} ${styles.summaryItemTotal}`}>
                {t('general:total')}
                <span>
                  {formatNumber(
                    (selectedOption === OPTIONS[0] ? MONTHLY_PRICE : YEARLY_PRICE * 12) *
                    unsubscribedContracts.length
                  )}
                </span>
              </li>
            </ul>
            <CheckoutForm billingCycle={selectedOption}  updatePaymentMethodRequired={checkoutInformation.update_payment_method_required}  handleCloseDialog={handleCloseDialog}  handleOpenDialog={handleClick} />
          </Dialog>
        </div>
      </section>
    </Stepper>
  );
};

export default StripeCheckout;
