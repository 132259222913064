export const BILL_VIEW = 'bill';
export const CONTRACT_VIEW = 'contract';

export const MAX_TOTAL_SIZE_MB = 10;
export const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024;

export const LOGIN_FORM_INPUTS = {
  email: '',
  password: '',
};

export const NEW_PASSWORD_REQUEST_FORM_INPUTS = {
  email: '',
};

export const WAITLIST_FORM_INPUTS = {
  email: '',
};

export const PASSWORD_FORM_INPUTS = {
  new_password: '',
  confirm_new_password: '',
};

export const INVITE_FRIEND = {
  email: '',
};

export const UPDATE_ACCOUNT_PASSWORD = {
  password: '',
  new_password: '',
  confirm_new_password: '',
};

export const EMAIL_FORM_INPUTS = {
  email: '',
};

export const ACCOUNT_NEW_USER_FORM_INPUTS_PERSON = {
  name: '',
  first_surname: '',
  second_surname: '',
  id_number: '',
  phone_number: '',
  is_privacy_policy_checked: false,
};

export const ACCOUNT_NEW_USER_FORM_INPUTS_COMPANY = {
  ...ACCOUNT_NEW_USER_FORM_INPUTS_PERSON,
  nif: '',
  corporate_name: '',
  office_address: '',
};

export const ACCOUNT_ADDRESS_FORM_FILE = {
  file: '',
};

export const ACCOUNT_ADDRESS_FORM_INPUTS = {
  address_street: '',
  address_number: '',
  address_stair: '',
  address_floor_number: '',
  address_door_number: '',
  city: '',
  zip_code: '',
  user_is_contract_holder: '',
};

export const OFFBOARD_CONTRACT = {
  offboard: '',
};

export const GET_IN_TOUCH = {
  request: '',
};

export const UPDATE_IBAN = {
  new_iban: '',
  new_iban_confirm: '',
};

export const ACCOUNT_AUTHORISATION_FORM_INPUTS = (contractsNotOnboarded) => ({
  contracts: contractsNotOnboarded.map((contract) => ({
    contract_id: contract.contract_id,
    product_type: contract.product_type,
    address: contract.address,
    iban: '',
  })),
});

export const ACCOUNT_IBAN_FORM_INPUTS = (contractsNotOnboarded) => ({
  contracts: contractsNotOnboarded.map((contract) => ({
    contract_id: contract.contract_id,
    product_type: contract.product_type,
    address: contract.address,
    iban: '',
  })),
});
