import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone';

import { ROUTES } from '../../../routes';
import styles from "./AdminOperations.module.css";
import { useAdmin } from '../../../context';
import services from "../../../services";
import { Loader } from '../../Loader';
import React, { useState } from 'react';

const {
  getLateLightBills,
  getContractsToOnboard,
  getGeneralStatus,
  getBillsToSend,
  getNonProcessedOnboardingBills,
  getOnboardingBillsToVerify,
  getOnboardedContractsToVerify,
} = services;

const AdminOperation = () => {
  const { setLightCustomersData, setLightContractsToOnboard, setGeneralStatus, setBillsToSend, setNonProcessedOnboardingBills, setOnboardingBillsToVerify, setOnboardedContractsToVerify } = useAdmin();
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();

  const handleNextStep = async (e) => {
    e.preventDefault();
    const buttonValue = e.target.value;

    switch (buttonValue) {
      case "button1":
        setLoading(true);
        const res = await getLateLightBills();
        setLoading(false);
        setLightCustomersData(res);
        navigate(`${ROUTES.ADMIN}${ROUTES.LATE_LIGHT_BILLS}`);
        break;
      case "button2":
        setLoading(true);
        const res_2 = await getContractsToOnboard();
        setLoading(false);
        setLightContractsToOnboard(res_2);
        navigate(`${ROUTES.ADMIN}${ROUTES.CONTRACTS_TO_ONBOARD}`);
        break;
      case "button3":
        navigate(`${ROUTES.ADMIN}${ROUTES.ADD_NEW_BILLS}`);
        break;
      case "button4":
        navigate(`${ROUTES.ADMIN}${ROUTES.IMPERSONATE_USER}`);
        break;
      case "button5":
        setLoading(true);
        const res_5 = await getGeneralStatus();
        setLoading(false);
        setGeneralStatus(res_5);
        navigate(`${ROUTES.ADMIN}${ROUTES.GENERAL_STATUS}`);
        break;
      case "button6":
        setLoading(true);
        const res_6 = await getBillsToSend();
        setLoading(false);
        setBillsToSend(res_6);
        navigate(`${ROUTES.ADMIN}${ROUTES.BILLS_TO_SEND}`);
        break;
      case "button7":
        setLoading(true);
        const res_7 = await getNonProcessedOnboardingBills();
        setLoading(false);
        setNonProcessedOnboardingBills(res_7);
        navigate(`${ROUTES.ADMIN}${ROUTES.NON_PROCESSED_BILLS}`);
        break;
      case "button8":
        setLoading(true);
        const res_8 = await getOnboardingBillsToVerify();
        setLoading(false);
        setOnboardingBillsToVerify(res_8);
        navigate(`${ROUTES.ADMIN}${ROUTES.ONBOARDING_BILLS_TO_VERIFY}`);
        break;
      case "button9":
        setLoading(true);
        const res_9 = await getOnboardedContractsToVerify();
        setLoading(false);
        setOnboardedContractsToVerify(res_9);
        navigate(`${ROUTES.ADMIN}${ROUTES.ONBOARDED_CONTRACTS_TO_VERIFY}`);
        break;
      case "button10":
        navigate(ROUTES.DASHBOARD);
        break;
      default:
        console.log("Invalid button");
    }
  };

  return (
    <div className="form-container">
    {loading && <Loader />}
      <div className="headerContainer">
        <h1>¿Qué operacion quiere llevar a cabo?</h1>
      </div>
      <div className={styles.root}>
        <div className={styles.gridButtons}>
          <button
            className={styles.controlButton}
            type="button"
            value="button1"
            onClick={handleNextStep}
          >
            Clientes de luz/gas que no han recibido facturas recientemente
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button2"
            onClick={handleNextStep}
          >
            Contratos que han de darse da alta
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button3"
            onClick={handleNextStep}
          >
            Añadir nuevas facturas
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button4"
            onClick={handleNextStep}
          >
            Loggearse como otro usuario
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button5"
            onClick={handleNextStep}
          >
            General status
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button6"
            onClick={handleNextStep}
          >
            Ver las facturas a enviar
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button7"
            onClick={handleNextStep}
          >
            Ver facturas onboarding con problemas
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button8"
            onClick={handleNextStep}
          >
            Ver las facturas onboarding a verificar
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button9"
            onClick={handleNextStep}
          >
            Contratos a verificar
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button10"
            onClick={handleNextStep}
          >
            Ir al dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminOperation;
