import React, { useState } from "react";
import { useAdmin } from "../../../context/AdminContext";
import services from "../../../services";

const { onboardNewContracts } = services;

const UsersToOnboard = () => {
  const { lightContractsToOnboard } = useAdmin();
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  const allFields = lightContractsToOnboard
    ? Array.from(new Set(lightContractsToOnboard.flatMap(Object.keys))).filter(
        (field) => field !== "contract_id"
      )
    : [];

  const handleCheckboxChange = (customer) => {
    setSelectedCustomers((prevSelected) => {
      if (prevSelected.includes(customer)) {
        return prevSelected.filter((item) => item !== customer);
      } else {
        return [...prevSelected, customer];
      }
    });
  };

  const handleSend = () => {
    setSuccessMessage("Contratos firmados.");
    onboardNewContracts(selectedCustomers);
  };

  if (!lightContractsToOnboard || lightContractsToOnboard.length === 0) {
    return <div>Everybody up to date</div>;
  }

  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Customers to Onboard</h1>
      <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
          <thead>
            <tr style={{ backgroundColor: "#f4f4f4" }}>
              <th style={headerCellStyle}>Select</th>
              {allFields.map((field) => (
                <th key={field} style={headerCellStyle}>{field}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {lightContractsToOnboard.map((customer, index) => (
              <tr key={index}>
                <td style={cellStyle}>
                  <input
                    type="checkbox"
                    checked={selectedCustomers.includes(customer)}
                    onChange={() => handleCheckboxChange(customer)}
                  />
                </td>
                {allFields.map((field) => (
                  <td key={field} style={cellStyle}>
                    {field === "offer_url" && customer[field] ? (
                      <a
                        href={customer[field]}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#007bff", textDecoration: "none" }}
                      >
                        {customer["offer_name"] || "Link"}
                        </a>
                    ) : field === "bill_url" && customer[field] ? (
                      <a
                        href={customer[field]}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#007bff", textDecoration: "none" }}
                      >
                        bill
                      </a>
                    ) : field === "change_power" ? (
                      typeof customer[field] === "object" && customer[field] !== null ? (
                        <>
                          <p>Peak: {customer[field]?.peak}</p>
                          <p>Valley: {customer[field]?.valley}</p>
                          <p>P.Original: {customer[field]?.peak_original}</p>
                          <p>V.Original: {customer[field]?.valley_original}</p>
                        </>
                      ) : (
                        "null"  
                      )
                    ) : field === "authorization" && customer[field] ? (
                      <a
                        href={customer[field]}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#007bff", textDecoration: "none" }}
                      >
                        auth
                      </a>
                  ) : (
                    typeof customer[field] === "boolean" ? 
                      customer[field].toString() : 
                      (customer[field] !== null && customer[field] !== undefined ? 
                        customer[field] : 
                        "null")
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        onClick={handleSend}
        style={{
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          display: "block",
          margin: "0 auto",
        }}
      >
        Signed
      </button>
      {successMessage && <p className="success">{successMessage}</p>}
    </div>
  );
};

const headerCellStyle = {
  padding: "10px",
  textAlign: "left",
  fontWeight: "bold",
  border: "1px solid #ddd",
};

const cellStyle = {
  padding: "10px",
  textAlign: "left",
  border: "1px solid #ddd",
};

export default UsersToOnboard;
