import { Link } from 'react-router-dom';
import { motion } from 'motion/react';
import { ROUTES } from '../../../routes';
import { Logo } from '../../Logo';

import { Icon, Icons } from '../../Icon';
import { Button } from '../../Button';
import styles from './Header.module.css';

const Header = () => {
  return (
    <motion.header
      className={styles.header}
      initial={{ opacity: 0, y: -4 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
    >
      <Link to={ROUTES.LANDING}>
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.menuItems}>
        <Button href={ROUTES.ROOT} isSecondary>
          <Icon size={20} name={Icons.UserLanding} />
          Acceder
        </Button>
        <Button href={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SIGN_UP}`} className={styles.button}>
          Empezar
        </Button>
      </div>
    </motion.header>
  );
};

export default Header;
