import { useState, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';

import useTranslations from '../../../../i18n/useTranslations';
import { ACCOUNT_ADDRESS_FORM_INPUTS } from '../../../../constants';
import { accountAddressSchema } from '../../../../validationSchemas';

import { Button } from '../../../Button';
import { FormikInput, RadioButtonGroup, RadioButton, Input } from '../../../Input';
import stylesForm from '../../../Form/Form.module.css';
import styles from './CorrectAddress.module.css';

const CorrectAddress = ({ data = [], onClose, onAddressesUpdated }) => {
  const { t } = useTranslations();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [correctedAddresses, setCorrectedAddresses] = useState([]);

  const sortedData = useMemo(() => {
    const nonEmptyAddresses = data.filter(item => Object.keys(item).length > 0);
    const emptyAddresses = data.filter(item => Object.keys(item).length === 0);
    return [...nonEmptyAddresses, ...emptyAddresses];
  }, [data]);

  const handleNext = () => {
    if (currentIndex < sortedData.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      onAddressesUpdated(correctedAddresses);
      onClose();
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (Object.keys(currentAddress).length === 0) {
        handleNext();
        return;
      }

      const correctedAddress = {
        ...values,
        product_type: sortedData[currentIndex]?.product_type || '',
        user_is_authorised: values.user_is_contract_holder === 'true' ? true : values.user_is_authorised,
      };

      setCorrectedAddresses((prev) => {
        const updatedAddresses = [...prev, correctedAddress];

        if (currentIndex < sortedData.length - 1) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          onAddressesUpdated(updatedAddresses);
          onClose();
        }

        return updatedAddresses;
      });

      resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const currentAddress = sortedData[currentIndex] || {};
  const estimatedAddress = currentAddress.estimated_address || {};

  if (Object.keys(currentAddress).length === 0) {
    return (
      <div className={styles.errorWrapper}>
        <h2 className={styles.heading}>{'No pudimos leer tus archivos'}</h2>
        <p className={styles.subheading}>{'Parece que no pudimos obtener la información de la dirección. Por favor, adjunta todas las paginas de la factura y con una resolución óptima.'}</p>
        <p className={styles.subheading}>{<a href="https://wa.me/+34658931938?text=Quiero%20enviar%20mis%20facturas" target="_blank" rel="noopener noreferrer" style={{   textDecoration: 'none',   color: 'var(--brand-400)',   transition: 'color var(--transition-normal)' }}> {t('account_address:whatsapp')} </a>}</p>
        <div className={styles.actionError}>
          <div className={styles.buttonWrapper}>
            <Button isFullWidth onClick={handleNext}>{'Siguiente dirección'}</Button>
          </div>
        </div>
      </div>
    );
  }

  const nonNullAddressFields = Object.fromEntries(
    Object.entries(estimatedAddress).filter(([_, value]) => value !== null)
  );

  const initialValues = {
    ...ACCOUNT_ADDRESS_FORM_INPUTS,
    ...nonNullAddressFields,
    cups: currentAddress?.cups?.[currentAddress?.product_type] || '',
    city: estimatedAddress.city || '',
    zip_code: estimatedAddress.zip_code || '',
    user_is_contract_holder: '',
    user_is_authorised: ''
  };

  return (
    <>
      <h2 className={styles.heading}>{'Comprueba tus datos'}</h2>
      <p className={styles.subheading}>{`${currentIndex + 1} / ${sortedData.length}`}</p>
      <div className={styles.action}>
        <Formik
          initialValues={initialValues}
          validationSchema={accountAddressSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values }) => (
            <Form className={stylesForm.form} noValidate>
              <div className={stylesForm.formGroup}>
                <fieldset className={`${stylesForm.fieldset} ${stylesForm.fieldsetGrid}`}>
                  <div>
                    <Input
                      label={t('general:cups')}
                      name="cups"
                      disabled
                      value={currentAddress?.cups?.[currentAddress?.product_type]}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:address_street'}
                      name="address_street"
                      error={errors['address_street']}
                      touched={touched['address_street']}
                      required
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:address_number'}
                      name="address_number"
                      error={errors['address_number']}
                      touched={touched['address_number']}
                      required
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:address_stair'}
                      name="address_stair"
                      error={errors['address_stair']}
                      touched={touched['address_stair']}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:address_floor_number'}
                      name="address_floor_number"
                      error={errors['address_floor_number']}
                      touched={touched['address_floor_number']}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:address_door_number'}
                      name="address_door_number"
                      error={errors['address_door_number']}
                      touched={touched['address_door_number']}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:city'}
                      name="city"
                      error={errors['city']}
                      touched={touched['city']}
                      required
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={'input:zip_code'}
                      name="zip_code"
                      error={errors['zip_code']}
                      touched={touched['zip_code']}
                      required
                    />
                  </div>
                </fieldset>
                <fieldset className={stylesForm.fieldset}>
                <RadioButtonGroup
                  id="user_is_contract_holder"
                  label="input:user_is_contract_holder"
                  name="user_is_contract_holder"
                  error={errors['user_is_contract_holder']}
                  touched={touched['user_is_contract_holder']}
                >
                  <Field
                    component={RadioButton}
                    name="user_is_contract_holder"
                    id="user_is_contract_holder_true"
                    label={t('option:yes')}
                    value="true"
                    checked={values.user_is_contract_holder === 'true'}
                  />
                  <Field
                    component={RadioButton}
                    name="user_is_contract_holder"
                    id="user_is_contract_holder_false"
                    label={t('option:no')}
                    value="false"
                    checked={values.user_is_contract_holder === 'false'}
                  />
                </RadioButtonGroup>

                {values.user_is_contract_holder === "false" && (
                  <RadioButtonGroup
                    id="user_is_authorised"
                    label="input:user_is_authorised"
                    name="user_is_authorised"
                    error={errors['user_is_authorised']}
                    touched={touched['user_is_authorised']}
                  >
                    <Field
                      component={RadioButton}
                      name="user_is_authorised"
                      id="user_is_authorised_true"
                      label={t('option:yes')}
                      value="true"
                      checked={values.user_is_authorised === 'true'}
                    />
                    <Field
                      component={RadioButton}
                      name="user_is_authorised"
                      id="user_is_authorised_false"
                      label={t('option:no')}
                      value="false"
                      checked={values.user_is_authorised === 'false'}
                    />
                  </RadioButtonGroup>
                )}



                </fieldset>
              </div>
              <div className={stylesForm.formActions}>
                <Button isFullWidth type="submit">
                  {currentIndex < sortedData.length - 1 ? 'Siguiente dirección' : 'Validar'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CorrectAddress;