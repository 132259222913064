import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import services from '../../../services';
import { NEW_PASSWORD_REQUEST_FORM_INPUTS } from '../../../constants';
import { newPasswordRequestSchema } from '../../../validationSchemas';

import { Link } from '../../Link';
import { PublicLayout } from '../../../Layout';
import { FormikInput } from '../../Input';
import { Button } from '../../Button';
import styles from './SignUp.module.css';
import stylesForm from '../../Form/Form.module.css';

const { signUpRequest } = services;

const SignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();

  const handleNextStep = async (values) => {
    try {
      const response = await signUpRequest(values);
      if (response.invitation_sent) {
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_RECOVER_PASS_SUCCESS}`);
      } else {
        navigate(ROUTES.DASHBOARD);
      }
      return;
    } catch (error) {
      navigate(ROUTES.ROOT);
    }
  };

  return (
    <PublicLayout>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('sign_up:heading')}</h1>
        </header>
        <div className={styles.content}>
          <Formik
            initialValues={NEW_PASSWORD_REQUEST_FORM_INPUTS}
            validationSchema={newPasswordRequestSchema}
            onSubmit={handleNextStep}
          >
            {({ errors, touched }) => (
              <Form className={stylesForm.form} noValidate>
                <div className={stylesForm.formGroup}>
                  <div>
                    <FormikInput
                      type="email"
                      label="input:email"
                      name="email"
                      error={errors['email']}
                      touched={touched['email']}
                      inputMode="email"
                      required
                    />
                  </div>

                </div>
                <div className={stylesForm.formActions}>
                  <Button isFullWidth type="submit">
                    {t('action:continue')}
                  </Button>
                </div>
                <div className={styles.marginLink}>
                  <p className={stylesForm.formText}>
                    {t('action:already_have_an_account')}
                    {' '}
                    <Link to={ROUTES.ROOT}>
                      {t('action:already_have_an_account_sign_in')}
                    </Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </PublicLayout>
  );
};

export default SignUp;
