import axios from 'axios';

const API_BASE_URL =
  process.env.REACT_APP_BACKEND_URL || 'https://backend-zenvi-5zv6ce4weq-no.a.run.app';
const API_ENDPOINT = 'api';
const API_ENDPOINT_USER = 'home';

const getUserData = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${API_BASE_URL}/${API_ENDPOINT_USER}/get_user_basic_information`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error getting user:', error);
    throw error;
  }
};

const getContracts = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${API_BASE_URL}/${API_ENDPOINT_USER}/get_users_cost_and_consumption`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error getting contracts:', error);
    throw error;
  }
};

const getContractsToCorrect = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_BASE_URL}/${API_ENDPOINT_USER}/get_contracts_to_correct_request`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.error('Error getting in touch:', error);
    throw error;
  }
};

const getAutomaticAdminActions = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${API_BASE_URL}/${API_ENDPOINT_USER}/automatic_admin_actions`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error getting user:', error);
    throw error;
  }
};

const getLatestBillsURLs = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${API_BASE_URL}/${API_ENDPOINT_USER}/get_latest_bill_urls`,
      body,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Error getting latest bill URLs:', error);
    throw error;
  }
};

const getNotifications = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${API_BASE_URL}/${API_ENDPOINT_USER}/get_notifications`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.notifications;
  } catch (error) {
    console.error('Error getting notifications:', error);
    throw error;
  }
};

const logout = async () => {
  return true;
  // try {
  //   const token = localStorage.getItem('token');
  //   await axios.post(
  //     `${API_BASE_URL}/auth/logout`,
  //     {},
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );
  // } catch (error) {
  //   console.error('Error during logout:', error);
  //   throw error;
  // }
};

const updateUserPassword = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${API_BASE_URL}/${API_ENDPOINT}/create_new_password_w_current`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating user password:', error);
    throw error;
  }
};

const offboardContract = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${API_BASE_URL}/${API_ENDPOINT_USER}/offboard_contract`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.error('Error offboarding contract:', error);
    throw error;
  }
};

const getInTouch = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${API_BASE_URL}/${API_ENDPOINT_USER}/miscellaneous_contract_request`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.error('Error getting in touch:', error);
    throw error;
  }
};

const updateIBAN = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_BASE_URL}/${API_ENDPOINT_USER}/modify_iban`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.error('Error updating IBAN:', error);
    throw error;
  }
};

const UserServices = {
  getUserData,
  getContracts,
  getLatestBillsURLs,
  getNotifications,
  logout,
  updateUserPassword,
  offboardContract,
  getInTouch,
  updateIBAN,
  getAutomaticAdminActions,
  getContractsToCorrect,
};

export default UserServices;
