import { useRef, useEffect, useState } from 'react';
import useTranslations from '../../../../i18n/useTranslations';
import styles from './Chart.module.css';

const ChartColumn = ({ item, index, isActive, data, handleActive, t }) => {
  const hasNoValues = item.cost === 0 && item.cost_wo_zenvi === 0;
  const isLastItem = index === data.inputs.length - 1 && hasNoValues;
  const hasPreviousValues = data.inputs
    .slice(0, index)
    .some((prev) => prev.cost > 0 || prev.cost_wo_zenvi > 0);

  const isEmpty = hasNoValues && !hasPreviousValues && !isLastItem;
  const isBiMonthly = hasNoValues && hasPreviousValues;

  const shouldShowCensored =
    (item.cost === undefined && item.cost_wo_zenvi === undefined) ||
    (isEmpty && !hasPreviousValues && !isLastItem);

  const ariaLabel =
    isEmpty || isBiMonthly
      ? t('dashboard:graph:chart_empty_aria_label', { date: item.full_date })
      : t('dashboard:graph:chart_aria_label', {
          date: item.full_date,
          zenvi: item.cost,
          standard: item.cost_wo_zenvi,
        });

  if (isLastItem) {
    return (
      <EmptyColumn
        item={item}
        ariaLabel={ariaLabel}
        isActive={isActive}
        height="25%"
        isLastItem={true}
        title={t('general:current-month')}
        handleActive={() => handleActive(item, index)}
      />
    );
  }

  if (shouldShowCensored) {
    return <CensoredColumn item={item} ariaLabel={ariaLabel} t={t} />;
  }

  if (isEmpty || (isBiMonthly && !isLastItem)) {
    return (
      <EmptyColumn
        item={item}
        ariaLabel={ariaLabel}
        isActive={isActive}
        height="50%"
        handleActive={hasPreviousValues ? () => handleActive(item, index) : undefined}
      />
    );
  }

  const costPercentage = Math.round((Math.floor(item.cost) * 100) / data.max_value);
  const costWoZenviPercentage =
    Math.round((Math.floor(item.cost_wo_zenvi) / data.max_value) * 100) || 100;

  return (
    <DataColumn
      item={item}
      ariaLabel={ariaLabel}
      isActive={isActive}
      costPercentage={costPercentage}
      costWoZenviPercentage={costWoZenviPercentage}
      handleActive={() => handleActive(item, index)}
    />
  );
};

const CensoredColumn = ({ item, ariaLabel, t }) => {
  return (
    <div
      key={item.issue_date}
      className={`${styles.column} ${styles.isCensored}`}
      aria-label={ariaLabel}
      style={{
        '--zenvi-cost-height': '50%',
        '--standard-cost-height': '100%',
      }}
    >
      <div className={styles.buttonWrapper}>
        <button className={styles.button} aria-hidden="true" disabled />
        <span className={styles.value} />
      </div>
      <span className={styles.text}>{item.issue_date || t('general:month')}</span>
    </div>
  );
};

const EmptyColumn = ({ item, ariaLabel, isActive, height, isLastItem, handleActive, title }) => {
  const shouldBeInteractive = handleActive !== undefined;
  const interactiveProps = shouldBeInteractive
    ? {
        onClick: handleActive,
        onFocus: handleActive,
        onMouseEnter: handleActive,
        onKeyDown: (e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleActive?.();
          }
        },
      }
    : {};

  return (
    <div
      key={item.issue_date}
      className={`${styles.column} ${styles.isEmpty} ${isActive ? styles.isActive : ''}`}
      aria-label={ariaLabel}
      aria-selected={isActive}
      {...interactiveProps}
      style={{
        '--zenvi-cost-height': '0%',
        '--standard-cost-height': height,
      }}
      title={title}
    >
      <button
        className={`${styles.button} ${styles.buttonEmpty} ${isLastItem ? styles.isLastItem : ''}`}
        aria-hidden="true"
        disabled={!shouldBeInteractive}
      />
      <span className={styles.text}>{item.issue_date}</span>
    </div>
  );
};

const DataColumn = ({
  item,
  ariaLabel,
  isActive,
  costPercentage,
  costWoZenviPercentage,
  handleActive,
}) => (
  <div
    key={item.issue_date}
    className={`${styles.column} ${styles.hasData} ${isActive ? styles.isActive : ''}`}
    aria-label={ariaLabel}
    aria-selected={isActive}
    onClick={handleActive}
    onFocus={handleActive}
    onMouseEnter={handleActive}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        handleActive();
      }
    }}
    style={{
      '--zenvi-cost-height': `${costPercentage}%`,
      '--standard-cost-height': `${costWoZenviPercentage}%`,
    }}
  >
    <div className={styles.buttonWrapper}>
      <button className={styles.button} />
      <span className={styles.value} />
    </div>
    <span className={styles.text}>{item.issue_date}</span>
  </div>
);

const Chart = ({ isEmpty, data, activeItem, handleActive }) => {
  const { t } = useTranslations();
  const panelRef = useRef(null);
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.scrollLeft = panelRef.current.scrollWidth;
      setHasScroll(panelRef.current.scrollWidth > panelRef.current.clientWidth);
    }
  }, []);

  const handleScroll = () => {
    if (panelRef.current) {
      setHasScroll(panelRef.current.scrollLeft > 0);
    }
  };

  useEffect(() => {
    const panel = panelRef.current;
    if (panel) {
      panel.addEventListener('scroll', handleScroll);
      return () => panel.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div
      ref={panelRef}
      className={styles.root}
      role="group"
      aria-label={t('dashboard:graph:chart_label')}
      data-has-scroll={hasScroll}
    >
      {isEmpty
        ? Array.from({ length: 12 }).map((_, index) => (
            <ChartColumn
              key={index}
              item={index}
              index={index}
              isActive={false}
              data={data}
              t={t}
            />
          ))
        : data.inputs.map((item, index) => (
            <ChartColumn
              key={item.issue_date}
              item={item}
              index={index}
              isActive={activeItem === index}
              data={data}
              handleActive={handleActive}
              t={t}
            />
          ))}
    </div>
  );
};

export default Chart;
