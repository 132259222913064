import styles from './LegalPolicies.module.css';

const CookiesPolicy = () => {
  return (
    <article className={styles.root}>
      <header>
        <h1>POLÍTICA DE COOKIES</h1>
      </header>
      <div className={styles.content}>
        <p>Última actualización: 12 de marzo de 2025</p>
        
        <p>Esta Política de Cookies explica qué son las Cookies y cómo las utilizamos. Debe leer esta política para comprender qué tipo de cookies utilizamos, la información que recopilamos mediante Cookies y cómo se utiliza esa información. Esta Política de Cookies se ha creado con la ayuda del <a href="https://www.freeprivacypolicy.com/free-cookies-policy-generator/" target="_blank">Generador Gratuito de Políticas de Cookies</a>.</p>
        
        <p>Las Cookies normalmente no contienen información que identifique personalmente a un usuario, pero la información personal que almacenamos sobre Usted puede estar vinculada a la información almacenada y obtenida de las Cookies. Para obtener más información sobre cómo usamos, almacenamos y mantenemos seguros sus datos personales, consulte nuestra Política de Privacidad.</p>
        
        <p>No almacenamos información personal sensible, como direcciones postales, contraseñas de cuentas, etc. en las Cookies que utilizamos.</p>
        
        <h2>INTERPRETACIÓN Y DEFINICIONES</h2>
        
        <h3>Interpretación</h3>
        
        <p>Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de si aparecen en singular o en plural.</p>
        
        <h3>Definiciones</h3>
        
        <p>A los efectos de esta Política de Cookies:</p>
        
        <ul>
          <li><strong>Empresa</strong> (referida como "la Empresa", "Nosotros", "Nos" o "Nuestro" en esta Política de Cookies) se refiere a Jiro Simplified Bills SL, Calle Príncipe de Vergara 94.</li>
          <li><strong>Cookies</strong> significa pequeños archivos que se colocan en su ordenador, dispositivo móvil o cualquier otro dispositivo por un sitio web, que contienen detalles de su historial de navegación en ese sitio web, entre otros usos.</li>
          <li><strong>Sitio web</strong> se refiere a Zenvi, accesible desde <a href="https://zenvi.es/" rel="external nofollow noopener" target="_blank">https://zenvi.es/</a></li>
          <li><strong>Usted</strong> significa la persona que accede o utiliza el Sitio web, o una empresa o cualquier entidad legal en cuyo nombre dicha persona accede o utiliza el Sitio web, según corresponda.</li>
        </ul>
        
        <h2>EL USO DE LAS COOKIES</h2>
        
        <h3>Tipos de Cookies que utilizamos</h3>
        
        <p>Las Cookies pueden ser "Persistentes" o de "Sesión". Las Cookies Persistentes permanecen en su ordenador personal o dispositivo móvil cuando se desconecta, mientras que las Cookies de Sesión se eliminan tan pronto como cierra su navegador web.</p>
        
        <p>Utilizamos tanto Cookies de sesión como persistentes para los fines establecidos a continuación:</p>
        
        <ul>
          <li>
            <p><strong>Cookies Necesarias / Esenciales</strong></p>
            <p>Tipo: Cookies de Sesión y Persistentes</p>
            <p>Administradas por: Nosotros</p>
            <p>Propósito: Estas Cookies son esenciales para proporcionarle servicios disponibles a través del Sitio web y para permitirle utilizar algunas de sus funciones. Ayudan a autenticar a los usuarios y prevenir el uso fraudulento de las cuentas de usuario. Sin estas Cookies, los servicios que ha solicitado no pueden proporcionarse, y solo utilizamos estas Cookies para proporcionarle esos servicios.</p>
          </li>
          <li>
            <p><strong>Cookies de Seguridad y Firma Electrónica (Dropbox Sign)</strong></p>
            <p>Tipo: Cookies de Sesión y Persistentes</p>
            <p>Administradas por: Dropbox Sign</p>
            <p>Propósito: Utilizamos Dropbox Sign (anteriormente HelloSign) para la firma electrónica de documentos. Dropbox Sign puede establecer cookies para gestionar sesiones, seguridad y autenticación. Estas cookies pueden incluir:</p>
            <ul>
              <li><strong>hs_uid</strong>: Cookie persistente utilizada para la autenticación del usuario.</li>
              <li><strong>hs_session</strong>: Cookie de sesión utilizada para gestionar la sesión de firma electrónica.</li>
            </ul>
            <p>Para más información, consulte la política de cookies de Dropbox Sign: <a href="https://www.dropbox.com/privacy" target="_blank">https://www.dropbox.com/privacy</a>.</p>
          </li>
          <li>
            <p><strong>Cookies de Seguridad y Prevención de Fraudes (Stripe)</strong></p>
            <p>Tipo: Cookies de Sesión y Persistentes</p>
            <p>Administradas por: Stripe</p>
            <p>Propósito: Nuestro proveedor de pagos, Stripe, utiliza cookies para prevenir fraudes y garantizar la seguridad de las transacciones. Entre ellas se incluyen:</p>
            <ul>
              <li><strong>__stripe_mid</strong>: Cookie persistente (1 año) utilizada para la prevención de fraudes y la identificación de usuarios.</li>
              <li><strong>__stripe_sid</strong>: Cookie de sesión utilizada para la gestión de sesiones y seguridad en las transacciones.</li>
            </ul>
            <p>Para más información, consulte la política de cookies de Stripe: <a href="https://stripe.com/cookies-policy/legal" target="_blank">https://stripe.com/cookies-policy/legal</a>.</p>
          </li>
          <li>
            <p><strong>Cookies de Funcionalidad</strong></p>
            <p>Tipo: Cookies Persistentes</p>
            <p>Administradas por: Nosotros</p>
            <p>Propósito: Estas Cookies nos permiten recordar las elecciones que hace cuando utiliza el Sitio web, como recordar sus datos de inicio de sesión o preferencia de idioma. El propósito de estas Cookies es proporcionarle una experiencia más personal y evitar que tenga que volver a introducir sus preferencias cada vez que utiliza el Sitio web.</p>
          </li>
        </ul>
        
        <h3>Sus opciones respecto a las Cookies</h3>
        
        <p>Si prefiere evitar el uso de Cookies en el Sitio web, primero debe deshabilitar el uso de Cookies en su navegador y luego eliminar las Cookies guardadas en su navegador asociadas a este sitio web. Puede usar esta opción para evitar el uso de Cookies en cualquier momento.</p>
        
        <p>Si no acepta Nuestras Cookies, puede experimentar algún inconveniente en su uso del Sitio web y algunas funciones pueden no funcionar correctamente.</p>
        
        <p>Si desea eliminar las Cookies o indicar a su navegador web que elimine o rechace las Cookies, visite las páginas de ayuda de su navegador web.</p>
        
        <ul>
          <li>
            <p>Para el navegador web Chrome, visite esta página de Google: <a href="https://support.google.com/accounts/answer/32050" rel="external nofollow noopener" target="_blank">https://support.google.com/accounts/answer/32050</a></p>
          </li>
          <li>
            <p>Para el navegador web Internet Explorer, visite esta página de Microsoft: <a href="http://support.microsoft.com/kb/278835" rel="external nofollow noopener" target="_blank">http://support.microsoft.com/kb/278835</a></p>
          </li>
          <li>
            <p>Para el navegador web Firefox, visite esta página de Mozilla: <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" rel="external nofollow noopener" target="_blank">https://support.mozilla.org/es/kb/delete-cookies-remove-info-websites-stored</a></p>
          </li>
          <li>
            <p>Para el navegador web Safari, visite esta página de Apple: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" rel="external nofollow noopener" target="_blank">https://support.apple.com/es-es/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></p>
          </li>
        </ul>
        
        <p>Para cualquier otro navegador web, visite las páginas web oficiales de su navegador.</p>
        
        <h3>Más información sobre las Cookies</h3>
        
        <p>Puede obtener más información sobre las cookies: <a href="https://www.freeprivacypolicy.com/blog/cookies/" target="_blank">Cookies: ¿Qué hacen?</a>.</p>
        
        <h3>Contáctenos</h3>
        
        <p>Si tiene alguna pregunta sobre esta Política de Cookies, puede contactarnos:</p>
        
        <ul>
          <li>Por correo electrónico: lopd@zenvi.es</li>
        </ul>
      </div>
    </article>
  );
};

export default CookiesPolicy;
