import { Link } from 'react-router-dom';
import { motion } from 'motion/react';

import { ROUTES } from '../../../routes';
import useTranslations from '../../../i18n/useTranslations';

import { Logo } from '../../Logo';
import styles from './Footer.module.css';

const Footer = () => {
  const { t } = useTranslations();
  return (
    <footer className={styles.root}>
      <div className={styles.wrapper}>
        <motion.div
          className={styles.logoWrapper}
          initial={{ opacity: 0, y: 4 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
          viewport={{ once: true, margin: '-160px' }}
        >
          <Link to={ROUTES.LANDING}>
            <Logo theme="light" className={styles.logo} />
          </Link>
        </motion.div>
        <div className={styles.boxLinks}>
          <Link to="mailto:hola@clienteszenvi.es">hola@clienteszenvi.es</Link>
          <ul className={styles.list}>
            <li>
              <Link to={ROUTES.LEGAL_NOTICE}>{t('general:title:legal_notice')}</Link>
            </li>
            <li>
              <Link to={ROUTES.PRIVACY_POLICY}>{t('general:title:privacy_policy')}</Link>
            </li>
            <li>
              <Link to={ROUTES.COOKIES_POLICY}>{t('general:title:cookies_policy')}</Link>
            </li>
          </ul>
          <p>&copy; {new Date().getFullYear()}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
