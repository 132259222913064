import { forwardRef } from 'react';
import useTranslations from '../../../../i18n/useTranslations';
import { Icons, Icon } from '../../../Icon';
import { Button } from '../../../Button';
import styles from './Dialog.module.css';

const Dialog = forwardRef(({ onClose, children, footer, heading, text }, ref) => {
  const { t } = useTranslations();
  return (
    <dialog ref={ref} className={styles.root}>
      <div className={styles.wrapper}>
        <header className={styles.header}>
        </header>
        <div className={styles.content}>
          {heading && <h2 className={styles.heading}>{heading}</h2>}
          {text && <p className={styles.text}>{text}</p>}
          {children}
        </div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </dialog>
  );
});

export default Dialog;