import useTranslations from '../../../i18n/useTranslations';
import styles from './Option.module.css';

const Option = ({ heading, price, onChange, value, selectedOption }) => {
  const { t, formatNumber } = useTranslations();
  const isSelected = value === selectedOption;

  return (
    <div>
      <label htmlFor={value} className={`${styles.option} ${isSelected && styles.isSelected}`}>
        <span className={styles.optionHeading}>{heading}</span>
        <p className={styles.optionPrice}>
          {t('general:price_monthly', { price: formatNumber(price) })}
        </p>
        <span className={styles.optionPriceYearly}>
          {t('general:price_yearly', { price: formatNumber(price * 12) })}
        </span>
      </label>
      <input
        name="option"
        id={value}
        type="radio"
        className={styles.input}
        value={value}
        checked={isSelected}
        onChange={onChange}
      />
    </div>
  );
};

export default Option;
