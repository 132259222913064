import styles from './LegalPolicies.module.css';

const LegalPolicy = () => {
  return (
 <article className={styles.root}>
 <header>
 <h1>AVISO LEGAL</h1>
 </header>
 <div className={styles.content}>
 <p>JIRO SIMPLIFIED BILLS S.L., provista de NIF número B70653878, es una sociedad de derecho español, inscrita en el Registro Mercantil de Madrid, hoja M-808033, tomo 45985, folio 145 (en adelante "Zenvi"). La sociedad es titular del nombre de dominio https://zenvi.es/ (en adelante y conjuntamente, el "sitio web").</p>
 
 <p><strong>Dirección:</strong> Calle Príncipe de Vergara 94, escalera B, 8º Izquierda.</p>
 
 <p><strong>Correo electrónico:</strong> hola@clienteszenvi.es.</p>
 
 <h2>CONDICIONES GENERALES DE USO</h2>
 
 <p>El Usuario accede voluntariamente a este sitio web, lo que implica que lo utilizará conforme al presente aviso legal, la ley, la moral, la buena fe y/o las buenas costumbres.</p>
 
 <p>El usuario del sitio web tiene derecho a acceder y utilizar los contenidos y servicios ofrecidos por JIRO SIMPLIFIED BILLS S.L. ("Zenvi") de acuerdo con los términos y condiciones establecidos en este aviso legal. El usuario se compromete a utilizar el sitio web de manera diligente, correcta y lícita, absteniéndose de realizar cualquier acto que pueda dañar, inutilizar, sobrecargar o deteriorar el sitio web o impedir su normal utilización por parte de otros usuarios. Asimismo, el usuario se obliga a no utilizar los contenidos del sitio web con fines o efectos ilícitos, contrarios a la buena fe y al orden público. Zenvi se reserva el derecho de restringir, suspender o cancelar el acceso al sitio web a aquellos usuarios que incumplan estas obligaciones, sin necesidad de previo aviso.</p>
 
 <p>La utilización de este sitio web supone la aceptación de este aviso legal.</p>
 
 <p>Las páginas web de este sitio web pueden ser visitadas libremente por los usuarios y no requiere la suscripción previa o el registro de usuarios.</p>
 
 <p>JIRO SIMPLIFIED BILLS S.L. se reserva el derecho a modificar sin previo aviso el contenido de este aviso legal, por tanto, y para un correcto empleo del sitio web, le recomendamos que visite periódicamente esta sección.</p>
 
 <h2>PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
 
 <p>Zenvi por sí, como titular originario o derivativo, como cesionaria o licenciataria, es titular de todos los derechos de propiedad intelectual e industrial de su página web, estén registrados o no, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de Zenvi o bien de sus licenciantes o cedentes.</p>
 
 <p>El uso de la Página por el Usuario en modo alguno supone la cesión de ningún derecho de propiedad intelectual y/o industrial sobre la Página, sus contenidos y/o los signos distintivos de Zenvi. A tal efecto, mediante las presentes Condiciones Legales quedan expresamente reservados todos los derechos y, salvo en aquellos supuestos en los que esté legalmente permitido, queda expresamente prohibido al Usuario, entre otros actos, la reproducción, transformación, distribución, comunicación pública, puesta a disposición, extracción y/o reutilización de la Página, sus contenidos y/o los signos distintivos de Zenvi, salvo autorización previa y por escrito de Zenvi.</p>
 
 <p>El Usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de Zenvi. Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El Usuario deberá abstenerse de suprimir, alterar, eludir o manipular los derechos de propiedad intelectual e industrial, así como el "copyright" y demás datos identificativos de los derechos de sus titulares incorporados a los contenidos de la Página, así como cualquier dispositivo de protección o sistema de seguridad o "DRM" que estuviera instalado en las páginas de Zenvi.</p>
 
 <h2>LIMITACIÓN DE LA RESPONSABILIDAD</h2>
 
 <p>De Zenvi: En la máxima medida permitida por la legislación aplicable y atendiendo a los máximos estándares de calidad que rigen nuestra actuación, Zenvi informa al Usuario de que la Página podrá contener errores o deficiencias. Zenvi hará sus mejores esfuerzos para, en su caso, identificarlos, evitarlos, subsanarlos, o actualizar el Contenido de la Página. Zenvi tratará de evitar que se produzcan estos errores y, dentro de los estándares admisibles y cuando el evento se escape de su control, no asumirá responsabilidad derivada, a título enunciativo, pero no limitativo, de:</p>
 
 <ul>
 <li>Errores u omisiones en los contenidos, falta de disponibilidad de la Página o la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo. La calidad del servicio, la velocidad de acceso, el correcto funcionamiento ni la disponibilidad ni continuidad de funcionamiento de la Página.</li>
 
 <li>Los daños que puedan causarse en los equipos del Usuario por la utilización de la Página.</li>
 
 <li>El incumplimiento de la ley, la moral y las buenas costumbres generalmente aceptadas o el orden público como consecuencia de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos.</li>
 
 <li>La desactualización de los datos facilitados por el Usuario, así como de la facilitación de datos erróneos o no veraces facilitados haciendo uso de los servicios de la Página.</li>
 
 <li>El acceso de menores de edad siendo responsabilidad de sus progenitores o tutores ejercer un control adecuado sobre la actividad de los hijos o menores a su cargo o bien instalar alguna de las herramientas de control del uso de Internet con el objeto de evitar su acceso, así como el envío de datos personales sin la previa autorización de sus progenitores o tutores.</li>
 
 <li>Errores o retrasos del Usuario a la hora de introducir sus datos en los formularios habilitados, la lentitud o cualquier anomalía que pueda surgir cuando estas incidencias sean debidas a problemas en la red Internet, causas de caso fortuito o fuerza mayor y cualquier otra contingencia imprevisible ajena a la buena fe de Zenvi.</li>
 
 <li>La falta de operatividad o problemas en la dirección de correo electrónico facilitada por el Usuario.</li>
 
 <li>Los daños y perjuicios de cualquier tipo causados en los equipos informáticos del Usuario por virus, gusanos informáticos, troyanos o cualquier otro elemento dañino. El Usuario reconoce que el uso de la red Internet supone la asunción de un riesgo de que sus equipos informáticos puedan ser afectados por los elementos antes enunciados. A tal efecto, corresponde al Usuario, en todo caso, la disponibilidad de herramientas adecuadas para la detección y eliminación de programas electrónicos dañinos en sus propios dispositivos. Para más información puede acudir a su proveedor de servicios de acceso a Internet que podrá facilitarle soluciones adecuadas a sus necesidades.</li>
 
 <li>Los daños y perjuicios de cualquier tipo producidos al Usuario que traigan causa en fallos o desconexiones en las redes de telecomunicaciones que produzcan la suspensión, cancelación o interrupción del servicio de la Página durante la prestación del mismo o con carácter previo. A este respecto, el Usuario reconoce que el acceso a la Página requiere de servicios suministrados por terceros ajenos al control de Zenvi (a modo de ejemplo: operadores de redes de telecomunicaciones, proveedores de acceso, etc.) cuya fiabilidad, calidad, continuidad y funcionamiento no corresponde a Zenvi.</li>
 </ul>
 
 <p>Del Usuario: En la máxima medida permitida por la legislación aplicable, el Usuario será responsable, a título enunciativo, pero no limitativo, de:</p>
 
 <ul>
 <li>De los Contenidos introducidos por ellos, especialmente de los datos e informaciones introducidos y enviados a Zenvi a través de los formularios disponibles, así como de facilitar datos veraces y mantenerlos actualizados.</li>
 
 <li>De la realización de cualquier tipo de actuación ilícita, lesiva de derechos, nociva y/o perjudicial.</li>
 
 <li>De la introducción, almacenamiento o difusión en o desde la Página de cualquier información o material que fuera difamatorio, injurioso, obsceno, amenazador, xenófobo, incite a la violencia a la discriminación por razón de raza, sexo, ideología, religión o que de cualquier forma atente contra la moral, el orden público, los derechos fundamentales, las libertades públicas, el honor, la intimidad o la imagen de terceros y en general la normativa vigente.</li>
 
 <li>De la introducción, almacenamiento o difusión mediante la Página de programas de ordenador, datos, virus, código, equipo de hardware o de telecomunicaciones o cualquier otro instrumento o dispositivo electrónico o físico que sea susceptible de causar daños en la Página, en cualquiera de los servicios, o en cualquiera de los equipos, sistemas o redes que dan soporte a la Página o en general de cualquier tercero, o que de cualquier otra forma sea capaz de causarles cualquier tipo de alteración o impedir el normal funcionamiento de los mismos.</li>
 
 <li>De custodiar adecuadamente la contraseña que haya facilitado para su registro como Usuario, como elemento identificador y habilitador para el acceso a los servicios asociados a dicho registro, comprometiéndose a no ceder su uso ni a permitir el acceso a terceros, asumiendo la responsabilidad por los daños y perjuicios que pudieran derivarse de un uso indebido de los mismos. Igualmente, el Usuario se compromete a comunicar a Zenvi con la mayor rapidez, su pérdida o robo, así como cualquier acceso que detecte que se ha producido de manera fraudulenta. Si detecta que su contraseña ha podido ser divulgada, por favor proceda a su cambio mediante el procedimiento de renovación de contraseña.</li>
 
 <li>Del cierre de su sesión o de deshabilitar el recordatorio de contraseñas de la Página en el caso de que acceda a la Página desde un dispositivo o equipo público o de uso compartido.</li>
 
 <li>De la introducción, almacenaje o difusión mediante la Página de cualquier contenido que infrinja derechos de propiedad intelectual, industrial o secretos empresariales de terceros y, en general cualquier Contenido del cual no ostentara, de conformidad con la ley, el derecho a ponerlo a disposición de tercero.</li>
 </ul>
 
 <h2>LEGISLACIÓN APLICABLE Y TRIBUNALES COMPETENTES</h2>
 
 <p>Las condiciones de este sitio web y las relaciones entre el usuario y JIRO SIMPLIFIED BILLS S.L., se regirán por la legislación y jurisdicción española. En caso de que el usuario tenga la condición de consumidor, cualquier disputa se someterá a los tribunales del lugar de residencia del consumidor. Para usuarios que no tengan la condición de consumidores, las partes se someten a los Juzgados y Tribunales de la ciudad de Madrid, España, con renuncia expresa a cualquier otro fuero que pudiera corresponderles.</p>
 
 <h2>POLÍTICA DE PRIVACIDAD Y COOKIES</h2>
 
 <p>Para obtener más información sobre cómo recopilamos, utilizamos y protegemos los datos personales, consulte nuestra <a href="https://zenvi.es/privacy-policy" target="_blank">Política de Privacidad</a>. Del mismo modo, para obtener información sobre el uso de cookies en nuestro sitio web, por favor consulte nuestra <a href="https://zenvi.es/cookies-policy" target="_blank">Política de Cookies</a>.</p>
 </div>
 </article>
  );
 };

export default LegalPolicy;
