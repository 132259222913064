import { Link } from 'react-router-dom';

import { ROUTES } from '../../routes';

import { useAuth } from '../../context/AuthContext';
import { Logo } from '../../components';
import styles from './PublicLayout.module.css';

const PublicLayout = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return (
    <main className={styles.root}>
      <header className={styles.header}>
        <Link to={isAuthenticated ? ROUTES.ROOT : ROUTES.LANDING}>
          <Logo />
        </Link>
      </header>
      <article className={styles.content}>{children}</article>
    </main>
  );
};

export default PublicLayout;
