import { motion } from 'motion/react';
import useTranslations from '../../../i18n/useTranslations';

import { Icon, Icons } from '../../Icon';
import imageHow from '../assets/landing-how.jpg';
import styles from './RationaleHow.module.css';

const RationaleHow = () => {
  const { t } = useTranslations();
  return (
    <motion.section
      className={styles.root}
      initial={{ opacity: 0, y: 32 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
      viewport={{ once: true, margin: '-48px' }}
    >
      <div className={styles.box}>
        <div className={styles.boxTexts}>
          <div className={styles.boxTitles}>
            <h2 className={styles.title}>{t('landing:rationaleHow:title')}</h2>
            <h3 className={styles.description}>{t('landing:rationaleHow:description')}</h3>
          </div>
          <ul className={styles.boxIcons}>
            <li>
              <Icon size={16} name={Icons.CheckCircle} />
              <span className={styles.iconText}>{t('landing:rationaleHow:icon:1')}</span>
            </li>
            <li>
              <Icon size={16} name={Icons.CheckCircle} />
              <span className={styles.iconText}>{t('landing:rationaleHow:icon:2')}</span>
            </li>
            <li>
              <Icon size={16} name={Icons.CheckCircle} />
              <span className={styles.iconText}>{t('landing:rationaleHow:icon:3')}</span>
            </li>
          </ul>
        </div>
        <motion.div
          className={styles.image}
          initial={{ opacity: 0, clipPath: 'inset(0 0 100% 0)' }}
          whileInView={{
            opacity: 1,
            clipPath: 'inset(0 0 0 0)',
            transition: { duration: 0.7, delay: 0.2 },
          }}
          viewport={{ once: true, margin: '-124px' }}
        >
          <img src={imageHow} loading="lazy" alt="" />
        </motion.div>
      </div>
    </motion.section>
  );
};

export default RationaleHow;
