// More info: https://www.freeprivacypolicy.com/free-cookie-consent/

import { useEffect } from 'react';
import './cookieConsent.css';

const BANNER_CLASSNAME = '.freeprivacypolicy-com---nb';

const CookieConsent = () => {
  useEffect(() => {
    const existingBanner = document.querySelector(BANNER_CLASSNAME);
    if (existingBanner) {
      existingBanner.remove();
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//www.freeprivacypolicy.com/public/cookie-consent/4.2.0/cookie-consent.js';

    script.onload = () => {
      window.cookieconsent.run({
        notice_banner_type: 'simple',
        consent_type: 'express',
        palette: 'light',
        language: 'es',
        page_load_consent_levels: ['strictly-necessary'],
        notice_banner_reject_button_hide: false,
        preferences_center_close_button_hide: false,
        page_refresh_confirmation_buttons: false,
        website_name: 'Zenvi',
        website_privacy_policy_url: `${window.location.origin}/cookies-policy`,
      });
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <noscript>
      Cookie Consent by{' '}
      <a href="https://www.freeprivacypolicy.com/">Free Privacy Policy Generator</a>
    </noscript>
  );
};

export default CookieConsent;
