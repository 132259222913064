import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { ROUTES } from '../../../../routes';
import useTranslations from '../../../../i18n/useTranslations';
import services from '../../../../services';
import { Callout } from '../../../Callout';
import { Button } from '../../../Button';
import { Input } from '../../../Input';
import stylesForm from '../../../Form/Form.module.css';
import styles from './CheckoutForm.module.css';
import './stripe-overrides.css';


const Loader = () => (
  <div className={styles.overlay}>
    <div className={styles.loader} />
  </div>
);

const { createSubscriptionStripe, updatePaymentMethodStripe } = services;
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_live_51QoNrP05lOCRlrW7BFSsF0auHJyAobUOH7n78m25nmhVt92kWVasJb0CCu2YzSnTesykNqHEJAhVyguNHipK6cU700Xk9X9Yvo';

const stripePromise = loadStripe(stripePublicKey);

const stripeOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@600&display=swap',
    },
    {
      family: 'Plus Jakarta Sans',
      src: 'url(https://fonts.gstatic.com/s/plusjakartasans/v3/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA.woff2)',
      weight: '600',
      style: 'normal',
    },
  ],
  locale: 'es',
};

const cardElementOptions = {
  style: {
    base: {
      color: '#181818',
      fontFamily: 'Plus Jakarta Sans, system-ui, sans-serif',
      fontSize: '16px',
      '::placeholder': {
        color: '#fff',
      },
    },
    invalid: {
      color: '#c4304d',
    },
  },
};

const CheckoutFormView = ({ billingCycle, updatePaymentMethodRequired, handleCloseDialog, handleOpenDialog }) => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setIsLoading(true);
    if (!stripe || !elements) return;
  
    try {
      const { paymentMethod, error: stripeError } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });
  
      if (stripeError) {
        console.error(stripeError);
        setError(true);
        return;
      }
  
      try {
        let res;
        if (updatePaymentMethodRequired){
          res = await updatePaymentMethodStripe({
            payment_method_id: paymentMethod.id,
          });
        }
        else {
          res = await createSubscriptionStripe({
            payment_method_id: paymentMethod.id,
            billing_cycle: billingCycle,
          });
        }

        setIsLoading(false);
  
        if (res.error) {
          console.error('Backend error:', res.error);
          setIsLoading(false);
          setError(true);
          return;
        }
  
        handleCloseDialog();
        if (res.length > 0) {
          for (const client_secret of res) {
            let confirmError, paymentIntent, setupIntent, error, intentResult;
            
            if (client_secret.startsWith("seti_")) {
              ({ error: confirmError, setupIntent: intentResult } = await stripe.confirmCardSetup(client_secret, {
                return_url: window.location.origin + `${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_IBAN}`,
              }));
            } else {
              ({ error: confirmError, paymentIntent: intentResult } = await stripe.confirmPayment({
                clientSecret: client_secret,
                confirmParams: {
                  return_url: window.location.origin + `${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_IBAN}`,
                },
                redirect: 'if_required',
              }));
            }
        
            if (confirmError) {
              handleOpenDialog();
              console.error('Payment confirmation failed:', confirmError);
              setError(true);
              return;
            }
            console.log('Payment succeeded:', intentResult.status);
            setError(false);
          }
        } else {
          console.log('Payment processed successfully without confirmation.');
          setError(false);
        }
        
        console.log('Payment Method Created:', paymentMethod.id);
        setIsLoading(false);
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_IBAN}`);
  
      } catch (backendError) {
        handleOpenDialog();
        console.error('Backend request failed:', backendError);
        setIsLoading(false);
        setError(true);
      }
    } catch (error) {
      handleOpenDialog();
      console.error('Unexpected error:', error);
      setError(true);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
    <form onSubmit={handleSubmit} className={stylesForm.form}>
      <div className={stylesForm.formGroup}>
        <fieldset className={stylesForm.fieldset}>
          <Input label={t('general:card:holder')} />
          <div className={styles.formField}>
            <label className={styles.label}>{t('general:card:number')}</label>
            <CardNumberElement className={styles.input} options={cardElementOptions} />
          </div>
          <div className={styles.row}>
            <div className={styles.formField}>
              <label className={styles.label}>{t('general:card:expiration_date')}</label>
              <CardExpiryElement className={styles.input} options={cardElementOptions} />
            </div>
            <div className={styles.formField}>
              <label className={styles.label}>{t('general:card:cvc')}</label>
              <CardCvcElement className={styles.input} options={cardElementOptions} />
            </div>
          </div>
          {error && (
            <Callout
              type="error"
              title={t('general:payment-error:title')}
              text={t('general:payment-error:text')}
            />
          )}
        </fieldset>
      </div>
      <div className={stylesForm.formActions}>
        <Button isFullWidth type="submit">
          {t('action:pay')}
        </Button>
      </div>
    </form>
    </>
  );
};

const CheckoutForm = ({ billingCycle, updatePaymentMethodRequired, handleCloseDialog, handleOpenDialog }) => {
  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <CheckoutFormView billingCycle={billingCycle} updatePaymentMethodRequired={updatePaymentMethodRequired} handleCloseDialog={handleCloseDialog} handleOpenDialog={handleOpenDialog} />
    </Elements>
  );
};

export default CheckoutForm;
