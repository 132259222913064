import PropTypes from 'prop-types';
import styles from './Logo.module.css';

const LogoPropTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
  className: PropTypes.string,
};

const Logo = ({ theme = 'dark', className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 97 26"
    className={`${styles.root} ${className ? className : ''}`}
  >
    <g clipPath="url(#a)">
      <path
        fill="#FE4902"
        d="M0 13.5C0 6.597 5.597 1 12.5 1S25 6.597 25 13.5 19.403 26 12.5 26 0 20.403 0 13.5Z"
      />
      <path
        fill="#fff"
        d="m19.85 13.955-3.205 4.44A1.475 1.475 0 0 1 15.46 19c-1.185 0-1.876-1.32-1.185-2.27l2.33-3.23-2.33-3.23c-.691-.95 0-2.27 1.185-2.27.47 0 .915.228 1.185.605l3.213 4.44a.785.785 0 0 1-.008.91Z"
      />
      <path
        fill="#fff"
        d="m10.931 18.395 2.932-4.44a.843.843 0 0 0 .007-.91l-2.939-4.44a1.37 1.37 0 0 0-.474-.444A1.255 1.255 0 0 0 9.847 8c-1.083 0-1.716 1.32-1.083 2.27l1.219 1.848H7.289c-.342 0-.67.146-.912.408A1.45 1.45 0 0 0 6 13.51c0 .369.136.723.377.984.242.261.57.408.912.408h2.68L8.765 16.73c-.633.95 0 2.27 1.084 2.27.429 0 .829-.227 1.083-.605Z"
      />
      <path
        fill={theme === 'dark' ? '#181818' : '#fff'}
        d="M92.926 4.161V0H97v4.16h-4.074v.001Zm0 16.506V5.52H97v15.147h-4.074Zm-9.805 0L77.28 5.521h4.4l3.205 9.071 3.205-9.07h4.4l-5.84 15.145h-3.53Zm-19.279 0V5.52h3.803v1.86c.76-1.499 2.28-2.192 4.155-2.192 3.34 0 5.622 2.302 5.622 5.742v9.737h-4.074v-8.85c0-1.747-1.114-2.884-2.716-2.884-1.603 0-2.716 1.137-2.716 2.885v8.85h-4.074ZM55.74 21C51.042 21 48 17.449 48 13.066c0-4.522 3.232-7.878 7.469-7.878 4.698 0 7.332 3.273 7.332 7.462 0 .61-.054 1.193-.19 1.609H52.237c.244 1.914 1.548 3.135 3.558 3.135 1.439 0 2.498-.639 3.068-1.665l3.26 1.581C61.225 19.53 58.781 21 55.74 21ZM55.47 8.516c-1.603 0-2.77.943-3.15 2.691h6.164c-.163-1.609-1.33-2.69-3.014-2.69ZM35 21v-3.297l7.44-8.132h-7.08V6h12.612v3.297l-7.219 8.132H48V21H35Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h97v26H0z" />
      </clipPath>
    </defs>
  </svg>
);

Logo.propTypes = LogoPropTypes;

export default Logo;
